import React from "react"
import PropTypes from "prop-types"

import { getAssetURL } from "helpers/static"
import formatterHelper from "helpers/formatter"

import i18n from "consts/i18n"

import styles from "./index.css"

const IMAGES = {
  lock: getAssetURL("image.pagePayment.lock"),
  americanExpress: getAssetURL("image.pagePayment.americanExpress"),
  visa: getAssetURL("image.pagePayment.visa"),
  mastercard: getAssetURL("image.pagePayment.mastercard"),
}

const FormFooter = ({ footerFormatter, isDesktop = false }) => (
  <div className={isDesktop ? styles.footerDesktop : styles.footer}>
    {!isDesktop && (
      <>
        <img loading="lazy" className={styles.footerImage} src={IMAGES.visa} />
        <img loading="lazy" className={styles.footerImage} src={IMAGES.mastercard} />
        <img loading="lazy" className={styles.footerImage} src={IMAGES.americanExpress} />
      </>
    )}
    <span className={styles.footerText}>
      {footerFormatter ? formatterHelper.styledFormatter(footerFormatter) : i18n.payment.paymentForm.securedServer}
    </span>
    <img loading="lazy" src={IMAGES.lock} />
  </div>
)

FormFooter.propTypes = {
  footerFormatter: PropTypes.object,
  isDesktop: PropTypes.bool,
}

export default FormFooter
