import { createSelector } from "reselect"
import { get } from "lodash"

import References from "models/References"
import Channel from "models/Channel"

const selectReferences = state => state.references

const selectSeo = state => state.seo

export default selectReferences

const selectCategories = createSelector(
  [selectReferences],
  references => {
    return get(references, "categories", [])
  }
)

const selectSubCategories = createSelector(
  [selectReferences],
  references => {
    return get(references, "kinds", [])
  }
)

export const selectChannels = createSelector(
  [selectReferences],
  references => {
    if (!references || references.pending || !references.channels) {
      return []
    }

    return references.channels.map(channel => new Channel(channel, references))
  }
)

const selectTvBundles = createSelector(
  [selectReferences, selectChannels, selectSeo],
  (references, channels, seo) => {
    const tvBundles = get(references, "tvbundles", [])
    const tvBundlesVisible = get(seo, "settings.tv_bundles_visible", [])

    if (!tvBundles.length || !channels.length) {
      return []
    }

    return tvBundles
      .filter(b => tvBundlesVisible.indexOf(b.id) !== -1)
      .map(bundle => {
        return {
          ...bundle,
          channels: channels.filter(c => bundle.channel_ids && bundle.channel_ids.indexOf(c.getId()) !== -1),
        }
      })
  }
)

const _selectReferencesModel = createSelector(
  [selectChannels, selectCategories, selectSubCategories, selectTvBundles],
  (channels, categories, subCategories, tvBundles) => {
    return new References({
      categories,
      channels,
      subCategories,
      tvBundles,
    })
  }
)

// In the use of the selector for getMeta client side, references
// is already the result of the selector
export const selectReferencesModel = state => {
  let references = state.references

  if (!(references instanceof References)) {
    references = _selectReferencesModel(state)
  }

  return references
}
