// Root components
import App from "containers/App"
import SEOWrapper from "containers/SEOWrapper"

// Lazy-loaded Components
import PageAbout from "containers/PageAbout/loader"
import PageAfrican from "containers/PageAfrican/loader"
import PageAmazonAlexa from "containers/PageAmazonAlexa/loader"
import PageAndroid from "containers/PageAndroid/loader"
import PageAppleTV from "containers/PageAppleTV/loader"
import PageBeta from "containers/PageBeta/loader"
import PageCampaign from "containers/PageCampaign/loader"
import PageCareers from "containers/PageCareers/loader"
import PageCategory from "containers/PageCategory/loader"
import PageChannel from "containers/PageChannel/loader"
import PageChromecast from "containers/PageChromecast/loader"
import PageCookiesSettings from "containers/PageCookiesSettings/loader"
import PageDeeplink from "containers/PageDeeplink/loader"
import PageDesktop from "containers/PageDesktop/loader"
import PageDevices from "containers/PageDevices/loader"
import PageDirect from "containers/PageDirect/loader"
import PageFreeChannels from "containers/PageFreeChannels/loader"
import PageGenre from "containers/PageGenre/loader"
import PageGOT from "containers/PageGOT/loader"
import PageGiftBuy from "containers/PageGifts/PageGiftBuy/loader"
import PageGiftClaim from "containers/PageGifts/PageGiftClaim/loader"
import PageGiftCongrats from "containers/PageGifts/PageGiftCongrats/loader"
import PageGiftList from "containers/PageGifts/PageGiftList/loader"
import PageGiftOptions from "containers/PageGifts/PageGiftOptions/loader"
import PageGoogleAssistant from "containers/PageGoogleAssistant/loader"
import PageHardware from "containers/PageHardware/loader"
import PageHome from "containers/PageHome/loader"
import PageHuawei from "containers/PageHuawei/loader"
import PageIphone from "containers/PageIphone/loader"
import PageLG from "containers/PageLG/loader"
import PageLink from "containers/PageLink/loader"
import PageMarketingOCS from "containers/PageMarketingOCS/loader"
import PageMarketingRMC from "containers/PageMarketingRMC/loader"
import PageMarketingAdultSwim from "containers/PageMarketingAdultSwim/loader"
import PageMytho from "containers/PageMytho/loader"
import PageWestworld from "containers/PageWestworld/loader"
import PageNotifications from "containers/PageNotifications/loader"
import PageOnMyTv from "containers/PageOnMyTv/loader"
import PageOpApp from "containers/PageOpApp/loader"
import PagePanasonic from "containers/PagePanasonic/loader"
import PageParentalControl from "containers/PageParentalControl/loader"
import PagePayment from "containers/PagePayment/loader"
import PagePdfViewer from "containers/PagePdfViewer/loader"
import PagePerson from "containers/PagePerson/loader"
import PageLettreTF1 from "containers/PageLettreTF1/loader"
import PageProductIframe from "containers/PageProductIframe/loader"
import PageProfile from "containers/PageProfile/loader"
import PageProgram from "containers/PageProgram/loader"
import PagePromoCode from "containers/PagePromoCode/loader"
import PageReplay from "containers/PageReplay/loader"
import PageSamsung from "containers/PageSamsung/loader"
import PageSEOHome from "containers/PageSEOHome/loader"
import PageSourcePanasonic from "containers/PageSourcePanasonic/loader"
import PageStorage from "containers/PageStorage/loader"
import PageStreaming from "containers/PageStreaming/loader"
import PageSubscribe from "containers/PageSubscribe/loader"
import PageTheMatch from "containers/PageTheMatch/loader"
import PageTravel from "containers/PageTravel/loader"
import PageLandingStrapi from "containers/PageLandingStrapi/loader"
import PageUnsubscribeEmail from "containers/PageUnsubscribeEmail/loader"
import PageUnsubscribeStep1 from "containers/PageUnsubscribeStep1/loader"
import PageUnsubscribeStep2 from "containers/PageUnsubscribeStep2/loader"
import PagePurgeBkm from "containers/PagePurgeBkm/loader"
import PageRickMorty from "containers/PageRickMorty/loader"
import PageXbox from "containers/PageXbox/loader"
import PageXiaomiStick from "containers/PageXiaomiStick/loader"
import PageHisense from "containers/PageHisense/loader"

// Components
import AuthenticationWrapper from "containers/AuthenticationWrapper"
import PageChangePassword from "containers/PageChangePassword"
import PageCreateAccountFromDSP from "containers/PageCreateAccountFromDSP"
import PageChangeEmail from "containers/PageChangeEmail"
import PageDownloading from "containers/PageDownloading"
import PageError from "containers/PageErrors/PageError"
import PageLegal from "containers/PageLegal"
import PageLegalText from "containers/PageLegalText"
import PageNotFound from "containers/PageErrors/PageNotFound"
import PageOAuth from "containers/PageOAuth"
import PageOCSAuth from "containers/PageOCSAuth"
import PageProducts from "containers/PageProducts"
import PageResetPassword from "containers/PageResetPassword"
import PageResetEmail from "containers/PageResetEmail"
import PageSubCategory from "containers/PageSubCategory"
import PublicWrapper from "containers/PublicWrapper"
import Nav from "components/Nav"

import routes from "consts/routes"
import consts from "./index"

/**
    Route configuration
    To render a <Route /> component:
    - path {string} - if not set, the route will match all path
    - paths {array} - array of path
    - component {component} - component rendered for the route
    - routeProps {object} - props that will be
      forwarded as is to the <Route /> component (NOT to the
      component rendered for the route)
    - modulePath {string} - Path to the module to require it for
      server-side rendering
    - loadable {object} (mandatory if the route is lazy-loaded)
    To render a <Redirect /> component:
    - from {string}
    - to {string}
*/

const iframeProductsUrl = [
  `${routes.products}/channel/:channelId`,
  `${routes.products}/channel/:channelId/program/:programId`,
  `${routes.products}/bookmarks`,
  `${routes.products}/url/:apiPath?`,
]

const docsRoutes = routes.docs.map(({ path, asset }) => {
  return {
    path,
    ...PagePdfViewer,
    routeProps: {
      exact: true,
    },
    componentProps: {
      asset,
    },
  }
})

export const rootRoutes = docsRoutes.concat([
  {
    paths: [routes.program, routes.channel, routes.channelReplay, "/:locale([a-z]{2}_[a-z]{2})", routes.deeplink, routes.category, routes.genre],
    component: SEOWrapper,
  },

  {
    path: routes.ocsAuth,
    component: PageOCSAuth,
  },

  {
    path: routes.oauth,
    component: PageOAuth,
  },

  {
    path: routes.linktv,
    ...PageLink,
  },

  {
    path: routes.ocs,
    ...PageMarketingOCS,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.rmc,
    ...PageMarketingRMC,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.adultSwim,
    ...PageMarketingAdultSwim,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.cguPdf,
    ...PagePdfViewer,
    routeProps: {
      exact: true,
    },
    componentProps: {
      pdf: "terms",
    },
  },

  {
    path: routes.privacyPdf,
    ...PagePdfViewer,
    routeProps: {
      exact: true,
    },
    componentProps: {
      pdf: "privacy",
    },
  },

  {
    path: routes.cookiesPdf,
    ...PagePdfViewer,
    routeProps: {
      exact: true,
    },
    componentProps: {
      pdf: "cookies",
    },
  },

  {
    component: App,
  },
])

export const appRoutes = [
  {
    paths: ["/account", ...iframeProductsUrl, "/gift/code", "/gift/buy", "/gift/options", "/gift/congrats", "/promo"],
    component: AuthenticationWrapper,
  },

  {
    path: routes.campaign,
    ...PageCampaign,
    routeProps: {
      exact: true,
    },
  },

  {
    component: PublicWrapper,
  },
]

export const SEORoutes = [
  {
    from: `${routes.seoHome}/en-ce-moment`,
    to: routes.seoHome,
  },
  {
    from: `${routes.seoHome}/deuxieme-partie-de-soiree`,
    to: `${routes.seoHome}/ce-soir`,
  },
  {
    paths: [routes.seoHomeSection, routes.seoHome],
    ...PageSEOHome,
  },

  {
    paths: [routes.categoryPage, routes.category],
    ...PageCategory,
    routeProps: {
      exact: true,
    },
  },

  {
    paths: [routes.genre, routes.genrePage],
    ...PageGenre,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.program,
    ...PageProgram,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.subCategory,
    component: PageSubCategory,
  },

  {
    paths: [routes.channel, routes.channelReplay],
    ...PageChannel,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.deeplink,
    ...PageDeeplink,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.person,
    ...PagePerson,
  },

  // Not Found
  {
    component: PageNotFound,
  },
]

export const publicRoutes = [
  // Home
  {
    path: "/",
    ...PageHome,
    routeProps: {
      exact: true,
    },
  },
  // Home
  {
    path: "/index.html",
    ...PageHome,
    routeProps: {
      exact: true,
    },
  },

  // About
  {
    path: routes.about,
    ...PageAbout,
    routeProps: {
      exact: true,
    },
  },

  // African
  {
    path: routes.african,
    ...PageAfrican,
    routeProps: {
      exact: true,
    },
  },

  // Android
  {
    path: routes.deviceAndroid,
    ...PageAndroid,
    routeProps: {
      exact: true,
    },
  },

  // Huawei
  {
    path: routes.deviceHuawei,
    ...PageHuawei,
    routeProps: {
      exact: true,
    },
  },

  // Source Panasonic Tv
  {
    path: routes.deviceSourcePanasonic,
    ...PageSourcePanasonic,
    routeProps: {
      exact: true,
    },
  },

  // Page Hisense
  {
    path: routes.deviceHisense,
    ...PageHisense,
    routeProps: {
      exact: true,
    },
  },

  // Apple Tv
  {
    path: routes.deviceAppleTv,
    ...PageAppleTV,
    routeProps: {
      exact: true,
    },
  },

  // Beta
  {
    path: routes.beta,
    ...PageBeta,
    routeProps: {
      exact: true,
    },
  },

  // Careers
  {
    path: routes.careers,
    ...PageCareers,
    routeProps: {
      exact: true,
    },
  },

  // Chromecast
  {
    path: routes.deviceChromecast,
    ...PageChromecast,
    routeProps: {
      exact: true,
    },
  },

  // Change Password
  {
    path: routes.changePassword,
    component: PageChangePassword,
    routeProps: {
      exact: true,
    },
  },

  // Change Email
  {
    path: routes.changeEmail,
    component: PageChangeEmail,
    routeProps: {
      exact: true,
    },
  },

  // Create Password
  {
    path: routes.createAccount,
    component: PageCreateAccountFromDSP,
    routeProps: {
      exact: true,
    },
  },

  // Desktop
  {
    path: routes.deviceDesktop,
    ...PageDesktop,
    routeProps: {
      exact: true,
    },
  },

  // Download
  {
    path: routes.download,
    component: PageDownloading,
    routeProps: {
      exact: true,
    },
  },

  // Error
  {
    path: routes.error,
    component: PageError,
    routeProps: {
      exact: true,
    },
  },

  // Game of thrones
  {
    path: routes.got,
    ...PageGOT,
    routeProps: {
      exact: true,
    },
  },

  // Rick et Morty
  {
    path: routes.rickMorty,
    ...PageRickMorty,
    routeProps: {
      exact: true,
    },
  },

  // Mytho
  {
    path: routes.mytho,
    ...PageMytho,
    routeProps: {
      exact: true,
    },
  },

  // Westworld
  {
    path: routes.westworld,
    ...PageWestworld,
    routeProps: {
      exact: true,
    },
  },

  // Free channels
  {
    path: routes.tvFreeChannels,
    ...PageFreeChannels,
    routeProps: {
      exact: true,
    },
  },

  // Gift list
  {
    path: routes.giftlist,
    ...PageGiftList,
    routeProps: {
      exact: true,
    },
  },

  // Hardware
  {
    path: routes.hardware,
    ...PageHardware,
    routeProps: {
      exact: true,
    },
  },

  // Iphone
  {
    path: routes.deviceIphone,
    ...PageIphone,
    routeProps: {
      exact: true,
    },
  },

  // Legal
  {
    path: routes.legal,
    component: PageLegal,
    routeProps: {
      exact: true,
    },
  },

  {
    paths: [routes.gtu, routes.privacy, routes.cookies, routes.mentions],
    component: PageLegalText,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.cookiesSettings,
    ...PageCookiesSettings,
    routeProps: {
      exact: true,
    },
  },

  // LG
  {
    path: routes.deviceLg,
    ...PageLG,
    routeProps: {
      exact: true,
    },
  },

  // Lettre TF1
  {
    path: routes.lettreTf1,
    ...PageLettreTF1,
    routeProps: {
      exact: true,
    },
  },

  // Press
  {
    path: routes.press,
    component: () => {
      window.location.href = consts.externalLink.press + "/fr/"
    },
  },

  // Press Release
  {
    path: routes.pressRelease,
    component: () => {
      window.location.href = consts.externalLink.press + "/en/"
    },
  },

  // Products
  {
    paths: [routes.products],
    component: PageProducts,
    routeProps: {
      exact: true,
    },
  },

  // Public offer
  {
    paths: [routes.publicOffer],
    ...PageProductIframe,
    routeProps: {
      exact: true,
    },
  },

  // Reset Password
  {
    path: routes.resetPassword,
    component: PageResetPassword,
    routeProps: {
      exact: true,
    },
  },

  // Reset Email
  {
    path: routes.resetEmail,
    component: PageResetEmail,
    routeProps: {
      exact: true,
    },
  },

  // On My TV
  {
    path: routes.tvPublic,
    ...PageOnMyTv,
    routeProps: {
      exact: true,
    },
  },

  // Travel
  {
    path: routes.travel,
    ...PageTravel,
    routeProps: {
      exact: true,
    },
  },

  // Landing Strapi
  {
    path: routes.landingStrapi,
    ...PageLandingStrapi,
    routeProps: {
      exact: true,
    },
  },

  // Samsung
  {
    path: routes.deviceSamsung,
    ...PageSamsung,
    routeProps: {
      exact: true,
    },
  },

  // Panasonic
  {
    path: routes.devicePanasonic,
    ...PagePanasonic,
    routeProps: {
      exact: true,
    },
  },

  // OP APP
  {
    path: routes.opApp,
    ...PageOpApp,
    routeProps: {
      exact: true,
    },
  },

  // Google Assistant
  {
    path: routes.deviceGoogleAssistant,
    ...PageGoogleAssistant,
    routeProps: {
      exact: true,
    },
  },

  // Xbox
  {
    path: routes.deviceXbox,
    ...PageXbox,
    routeProps: {
      exact: true,
    },
  },

  // Xiaomi Stick
  {
    path: routes.deviceXiaomi,
    ...PageXiaomiStick,
    routeProps: {
      exact: true,
    },
  },

  // Amazon Alexa
  {
    path: routes.deviceAmazonAlexa,
    ...PageAmazonAlexa,
    routeProps: {
      exact: true,
    },
  },

  // Subscribe
  {
    paths: [
      routes.subscribe,
      routes.travelProduct, // legacy url, clean later
    ],
    ...PageSubscribe,
    routeProps: {
      exact: true,
    },
  },

  // TV Direct
  {
    path: routes.tvDirect,
    ...PageDirect,
    routeProps: {
      exact: true,
    },
  },

  // TV Replay
  {
    path: routes.tvReplay,
    ...PageReplay,
    routeProps: {
      exact: true,
    },
  },

  // TV Streaming
  {
    path: routes.tvStreaming,
    ...PageStreaming,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.theMatch,
    ...PageTheMatch,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.unsubscribeEmail,
    ...PageUnsubscribeEmail,
    routeProps: {
      exact: true,
    },
  },

  // Not Found
  {
    component: PageNotFound,
  },
]

export const authenticatedRoutes = [
  {
    from: routes.account,
    to: routes.profile,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.profile,
    ...PageProfile,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.storage,
    ...PageStorage,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.parentalControl,
    ...PageParentalControl,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.payment,
    ...PagePayment,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.devices,
    ...PageDevices,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.tv,
    ...PageOnMyTv,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.offers,
    component: PageProducts,
    routeProps: {
      exact: true,
    },
  },

  {
    paths: [routes.offer, ...iframeProductsUrl],
    ...PageProductIframe,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.notifications,
    ...PageNotifications,
    routeProps: {
      exact: true,
    },
  },

  // Gift bought, congrats!
  {
    path: routes.giftcongrats,
    ...PageGiftCongrats,
    routeProps: {
      exact: true,
    },
  },

  // Gift code
  {
    path: routes.giftcode,
    ...PageGiftClaim,
    routeProps: {
      exact: true,
    },
  },

  // Gift options
  {
    path: routes.giftoptions,
    ...PageGiftOptions,
    routeProps: {
      exact: true,
    },
  },

  // Gift buy
  {
    path: routes.giftbuy,
    ...PageGiftBuy,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.promoCode,
    ...PagePromoCode,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.unsubscribeStep2,
    ...PageUnsubscribeStep2,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.unsubscribeStep1,
    ...PageUnsubscribeStep1,
    routeProps: {
      exact: true,
    },
  },

  {
    path: routes.purgeBkm,
    ...PagePurgeBkm,
    routeProps: {
      exact: true,
    },
  },

  // Not Found
  {
    component: PageNotFound,
  },
]

export const navRoutes = [
  {
    paths: [routes.profile, routes.storage, routes.parentalControl, routes.payment, routes.devices, routes.tv, routes.offers, routes.notifications],
    component: Nav,
    routeProps: {
      exact: true,
    },
  },

  // Not Found
  {
    component: null,
  },
]
