import React from "react"
import PropTypes from "prop-types"

import styles from "./index.css"

const DownloadStep = ({ img, number, title, instructions }) => (
  <div className={styles.root}>
    <img loading="lazy" src={img} className={styles.img} />
    <div className={styles.text}>
      <div className={styles.numberContainer}>
        <div className={styles.number}>{number}</div>
      </div>
      <div className={styles.title}>{title}</div>
      <p className={styles.instructions}>{instructions}</p>
    </div>
  </div>
)

DownloadStep.propTypes = {
  img: PropTypes.string,
  number: PropTypes.string,
  title: PropTypes.string,
  instructions: PropTypes.string,
}

export default DownloadStep
