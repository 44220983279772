import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { get } from "lodash"
import classNames from "classnames"

import InteractionButton from "containers/InteractionButton"
import Icon, { IconTypes } from "components/Icon"
import Separator from "components/Separator"
import Logo from "components/Logo"

import { postConversion } from "actions/subscriptions"

import { getAssetURL } from "helpers/static"
import formatterHelper from "helpers/formatter"

import styles from "./index.css"

@connect()
export default class Interstitial extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    equivalenceCode: PropTypes.string.isRequired,
    footer: PropTypes.object,
    interstitial: PropTypes.object.isRequired,
    isFramelessMode: PropTypes.bool,
    tracker: PropTypes.func.isRequired,
    trackerContext: PropTypes.string,
    dsp: PropTypes.string,
  }

  componentDidMount() {
    if (this.props.interstitial) {
      switch (this.props.interstitial.subtype) {
        case "post_conversion":
          this.props.tracker("confirmed", this.props.equivalenceCode)
          break
        case "error":
          this.props.tracker(
            this.props.trackerContext ? this.props.trackerContext + "_error" : "error",
            this.props.equivalenceCode,
            formatterHelper.toTextOnly(
              get(this.props.interstitial, "title_formatter.format"),
              get(this.props.interstitial, "title_formatter.parts")
            ) +
              " - " +
              formatterHelper.toTextOnly(
                get(this.props.interstitial, "message_formatter.format"),
                get(this.props.interstitial, "message_formatter.parts")
              )
          )
          break
        default:
          this.props.tracker(
            this.props.trackerContext ? this.props.trackerContext + "_" + this.props.interstitial.subtype : this.props.interstitial.subtype,
            this.props.equivalenceCode
          )
          break
      }
      this.props.dispatch(postConversion(this.props.equivalenceCode))
    }
  }

  render() {
    const { footer, interstitial, isFramelessMode, dsp } = this.props
    const dspLogo = getAssetURL(`image.dsp.${dsp}.logo`)
    const dspLogox2 = getAssetURL(`image.dsp.${dsp}.logox2`)

    let children

    switch (interstitial.subtype) {
      case "post_conversion":
        children = (
          <div className={styles.interstitialDefault} data-test="product-interstitial">
            {dsp && (
              <div className={styles.dspLogos}>
                <Logo height={42} />
                <span className={styles.logoSeparator}>avec</span>
                <img loading="lazy" src={dspLogo} srcSet={`${dspLogo} 1x, ${dspLogox2} 2x`} width={90} />
              </div>
            )}
            {interstitial.title ? (
              <>
                <div className={styles.interstitialTitle}>{interstitial.title}</div>
                <Separator customStyle={styles.separator} />
              </>
            ) : null}
            <Icon
              type={IconTypes.checkmark}
              rootStyle={classNames(styles.checkmarkIconRoot, { [styles.checkmarkRootDsp]: !!dsp })}
              iconStyle={classNames(styles.checkmarkIcon, { [styles.checkmarkIconDsp]: !!dsp })}
              iconProps={{ fill: dsp ? "#46b63d" : "" }}
            />

            {!isFramelessMode && interstitial.message_formatter ? (
              <div className={styles.interstitialMessage}>{formatterHelper.styledFormatter(interstitial.message_formatter)}</div>
            ) : null}
            {interstitial.footer_formatter ? (
              <div className={styles.footerFormatter}>
                <Icon type={IconTypes.alert} iconStyle={styles.iconAlert} rootStyle={styles.alertContainer} />
                <div className={styles.footerTextStyle}>{formatterHelper.styledFormatter(interstitial.footer_formatter)}</div>
              </div>
            ) : null}

            {/* Only visible on small viewport or with mobileDisplay */}
            {footer}
          </div>
        )
        break

      case "error":
        children = (
          <div className={styles.errorContainer}>
            <img loading="lazy" className={styles.exclamationMarkIcon} src={getAssetURL("image.pagePayment.exclamationmark")} />
            {interstitial.title_formatter ? (
              <div className={styles.titleDefault}>{formatterHelper.styledFormatter(interstitial.title_formatter)}</div>
            ) : null}
            {interstitial.message_formatter ? (
              <div className={styles.errorMessage}>{formatterHelper.styledFormatter(interstitial.message_formatter)}</div>
            ) : null}
            <div className={styles.boutonsContainer}>
              {get(interstitial, "interaction.buttons", []).map((button, index) => (
                <InteractionButton
                  customStyle={styles.interstitialBtn}
                  button={{
                    ...button,
                    actions: get(interstitial, "interaction.actions"),
                  }}
                  key={`button-${index}`}
                />
              ))}
            </div>
            {interstitial.footer_formatter ? (
              <div className={styles.footerFormatter}>
                <Icon type={IconTypes.alert} iconStyle={styles.iconAlert} rootStyle={styles.alertContainer} />
                <div className={styles.footerTextStyle}>{formatterHelper.styledFormatter(interstitial.footer_formatter)}</div>
              </div>
            ) : null}
          </div>
        )
        break

      default:
        children = (
          <div className={styles.interstitialDefault}>
            {interstitial.title_formatter ? (
              <>
                <div className={styles.titleDefault}>{formatterHelper.styledFormatter(interstitial.title_formatter)}</div>
                <Separator customStyle={styles.separator} />
              </>
            ) : null}
            {interstitial.message_formatter ? (
              <div className={styles.messageDefault}>{formatterHelper.styledFormatter(interstitial.message_formatter)}</div>
            ) : null}
            {interstitial.footer_formatter ? (
              <div className={styles.footerFormatter}>
                <Icon type={IconTypes.alert} iconStyle={styles.iconAlert} rootStyle={styles.alertContainer} />
                <div className={styles.footerTextStyle}>{formatterHelper.styledFormatter(interstitial.footer_formatter)}</div>
              </div>
            ) : null}
            {get(interstitial, "interaction.buttons", []).map((button, index) => (
              <div className={styles.buttonContainer} key={`button-${index}`}>
                <InteractionButton
                  button={{
                    ...button,
                    actions: get(interstitial, "interaction.actions"),
                  }}
                />
              </div>
            ))}

            {footer}
          </div>
        )
    }

    return children
  }
}
