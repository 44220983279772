import React from "react"
import { Link } from "react-router-dom"

import styles from "./index.css"
import consts from "consts"

export default {
  default: {
    breadcrumb: "Programme TV",
    h2: "Programme TV en ce moment sur Molotov",
    description: (
      <div>
        <h2 className={styles.bottomTextTitle}>Regardez la TV avec Molotov</h2>

        <div className={styles.bottomTextSection}>
          <p className={styles.subTitle}>Retrouvez votre programme TV sur Molotov : l’app pour regarder la télévision sur tous vos écrans !</p>
          <p>
            Molotov vous offre une expérience inégalée pour regarder vos chaînes préférées : TF1, France 2, France 3, M6, Arte, C8, BFM TV, TFX, NRJ
            12, Gulli, 6ter… Profitez de vos émissions à tout moment, sur tous vos appareils, en direct ou en replay.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <p className={styles.subTitle}>
            <strong>Accédez gratuitement au programme télé avec Molotov:</strong>
          </p>
          <ul>
            <li>
              <strong>Consultation simple et rapide: </strong>
              Grâce à notre interface intuitive, découvrez facilement le Programme TV complet de toutes vos chaînes préférées. Trouvez les émissions,
              séries, films et événements en cours ou à venir.
            </li>
            <li>
              <strong>Recherche et Filtrage: </strong>
              Utilisez des filtres personnalisés pour trouver rapidement les programmes qui correspondent à vos centres d'intérêt. Recherchez par
              catégorie, genre, horaire, ou encore par chaîne.
            </li>
            <li>
              <strong>Informations détaillées: </strong>
              Accédez à des informations détaillées sur chaque émission, y compris les résumés, les horaires de diffusion, les acteurs, les
              réalisateurs et plus encore.
            </li>
            <li>
              <strong>Alertes et rappels: </strong>
              Ne ratez plus jamais vos émissions ! Configurez des alertes pour être informé(e) lorsque vos programmes vont commencer.
            </li>
            <li>
              <strong>Programmes en Replay: </strong>
              Accédez au contenu en différé de nombreuses chaînes, pour ne pas manquer les émissions que vous avez ratées
            </li>
            <li>
              <strong>Personnalisation: </strong>
              Créez votre propre grille de programmes avec vos chaînes favorites pour une expérience de visionnage personnalisée.
            </li>
          </ul>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Ne manquez plus aucun programme grâce à l'enregistrement. Souscrivez à l’une de nos offres Premium et enregistrez vos émissions pour les
            regarder plus tard, sans aucune contrainte de temps. Vous pouvez même enregistrer plusieurs programmes en même temps.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <p className={styles.subTitle}>Retrouvez vos grilles télé sur une variété d'appareils:</p>
          <ul>
            <li>
              <strong>Appareils mobiles: </strong>
              <Link to={consts.routes.deviceIphone}>iPhone</Link>, <Link to={consts.routes.deviceIphone}>iPad</Link>, smartphones et tablettes{" "}
              <Link to={consts.routes.deviceAndroid}>Android</Link>.
            </li>
            <li>
              <strong>Ordinateurs: </strong>
              <Link to={consts.routes.deviceDesktop}>PC Windows, Mac et Linux</Link>.
            </li>
            <li>
              <strong>Téléviseurs: </strong>
              <Link to={consts.routes.deviceChromecast}>Chromecast</Link>, TV connectée <Link to={consts.routes.deviceLg}>LG</Link> et{" "}
              <Link to={consts.routes.deviceSamsung}>Samsung</Link>, <Link to={consts.routes.deviceAppleTv}>Apple TV</Link>,{" "}
              <Link to={consts.routes.deviceAndroid}>Android TV</Link>, Shield, etc.
            </li>
          </ul>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Suivez toute l’actu TV, organisez votre expérience de visionnage à votre façon, et retrouvez ce qui passe ce soir, en ce moment, ou demain
            à la télévision.
          </p>
        </div>
      </div>
    ),
  },
  tonight_1: {
    menuLabel: "TV ce soir",
    meta: {
      title: "Programme TV de ce soir sur toutes les chaînes TNT - Molotov.tv",
      description: "Retrouvez les programmes télé de ce soir et regardez la TNT en direct en téléchargeant l'application Molotov.",
    },
    h1: "Programme TV ce soir sur Molotov",
    h2: "Ce soir à la télé",
    breadcrumb: "Ce soir à la télé",
    description: (
      <div>
        <h2 className={styles.bottomTextTitle}>Votre programme TV complet de ce soir sur Molotov</h2>
        <div className={styles.bottomTextSection}>
          <p>Découvrez ce qui passe ce soir à la télévision avec Molotov.</p>
          <p>
            Le programme télé de votre soirée est disponible pour l'ensemble des chaînes disponibles sur nos applications dont vos chaînes TNT TF1,
            France 2, M6, W9, C8 ou encore BFM TV. Une fois votre émission choisie, installez-vous confortablement et regardez{" "}
            <Link to={consts.routes.tvStreaming}>la tv en streaming</Link>.
          </p>
          <p>En faisant visuellement le choix de ce qui sera diffusé ce soir à la télé, planifiez-vous une soirée tv parfaite avec Molotov !</p>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Sur ce programme TV, visualisez facilement la grille de vos emissions en première partie (à partir de 20h30) et en seconde partie de
            soirée tv (programmes commençant dès 22h) pour planifier votre fin de journée télévisuelle.
          </p>
          <p>
            Molotov propose au sein de ses offres payantes d'enregistrer vos programmes et émissions. Il est ainsi facile de découvrir les films à la
            télé ou les séries TV qui vont commencer pour les enregistrer en un clic et les regarder ensuite à votre rythme. L'application étant
            disponible sur de nombreux appareils, vous pouvez enregistrer le programme de votre choix sur notre{" "}
            <Link to={consts.routes.deviceIphone}>app pour iPhone</Link> ou <Link to={consts.routes.deviceAndroid}>l'appli tv pour Android</Link> et
            regarder ensuite ce programme sur un plus grand écran - que ce soit sur{" "}
            <Link to={consts.routes.tvPublic}>nos app pour télévisions connectées</Link> ou notre{" "}
            <Link to={consts.routes.deviceDesktop}>application tv pour ordinateur</Link>.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Le programme TV de ce soir est complet. Vous trouverez ainsi vos chaînes TNT (TF1, les chaînes de France Télévisions, Arte, M6, C8, W9,
            ...) mais aussi les chaînes disponibles dans nos <Link to={consts.routes.products}>options payantes</Link>. Retrouvez-ainsi ci-dessus les
            programmes pour l'option OCS et ses 4 chaînes 100% cinéma séries (OCS Max, OCS Choc, OCS City, OCS Géant), pour l'option Ciné+ dédiée au
            cinéma avec de grands films chaque soir ou encore ceux de l'option thématique Extended qui ravira toute la famille. A retrouver notamment
            les chaînes de qualité telles que Paris Première, Teva, AB1 sur du divertissement, Disney Channel, Canal J ou encore Toonami pour ravir
            les plus jeunes - ainsi que d'autres chaînes axées Musique, Documentaire ou Films.
          </p>
        </div>
      </div>
    ),
  },
}
