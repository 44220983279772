import React from "react"
import PropTypes from "prop-types"

import classNames from "classnames"

import styles from "./index.css"
import pictureHelper from "helpers/picture"
import { get } from "lodash"

const Thumbnail = props => {
  const { icon, thumbnail, dimensions, customStyle } = props
  const posterUrl = get(thumbnail, "program.poster_with_channel.medium.url")

  let { width: width = 118, height: height = 155 } = dimensions || {}

  if (!posterUrl) {
    return null
  }

  return (
    <div className={classNames(styles.thumbnail, customStyle)}>
      {icon}
      <img loading="lazy" src={pictureHelper.replaceProtocol(posterUrl)} width={width} height={height} />
    </div>
  )
}

Thumbnail.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  thumbnail: PropTypes.object,
  dimensions: PropTypes.object,
  customStyle: PropTypes.string,
}

export default Thumbnail
