import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import classNames from "classnames"

import Separator from "components/Separator"
import Icon, { IconTypes } from "components/Icon"

import TrackingSegment from "managers/segmentManager"

import dateHelper from "helpers/date"
import { getAssetURL } from "helpers/static"
import UrlHelper from "helpers/url"
import textFormatter from "helpers/formatter"

import i18n from "consts/i18n"
import consts from "consts"

import styles from "./index.css"

const check = getAssetURL("image.pagePayment.check")

@connect(state => {
  return {
    router: state.router.location,
  }
})
export default class ProductConfirmation extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    discount: PropTypes.object,
    subscriptionMessages: PropTypes.object,
    resiliationMessages: PropTypes.object,
    subscriptionEndAt: PropTypes.number,
    footer: PropTypes.object,
    isResiliation: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isFromTravelPage: PropTypes.bool,
    nextTemplate: PropTypes.bool,
    productName: PropTypes.string,
    warningMessage: PropTypes.object,
    productCode: PropTypes.string,
    productOnTrial: PropTypes.bool,
    router: PropTypes.object.isRequired,
  }

  static defaultProps = {
    subscriptionEndAt: 0,
    customStyle: "",
    isResiliation: false,
    productOnTrial: false,
  }

  openInBrowser = () => {
    const storedOffer = JSON.parse(sessionStorage.getItem(consts.sessionStorageKey.programOfferToSubscribe))

    sessionStorage.removeItem(consts.sessionStorageKey.programOfferToSubscribe)

    // user was redirected from a product CTA and paid for a subscription
    if (storedOffer?.url && storedOffer?.programOffer?.product_equivalence_code === this.props.productCode) {
      storedOffer.programOffer.isMobile ? (window.location = storedOffer.url) : UrlHelper.openInBrowser(storedOffer.url, true)
    } else {
      UrlHelper.openInBrowser()
    }
  }

  generateConfirmationMessage() {
    const isYearlySubscription = this.props.productCode.includes("1_AN")
    const isPriceDiscounted = this.props.discount

    if (isYearlySubscription) {
      return (
        <div className={styles.nextSubtitle}>
          Vous allez recevoir un e-mail de confirmation. Votre abonnement est annulable à tout moment. La résiliation prend effet à la fin de la
          période de facturation.
        </div>
      )
    }

    if (this.props.productOnTrial) {
      return (
        <div className={styles.nextSubtitle}>
          Vous allez recevoir un e-mail de confirmation. Vous ne paierez rien jusqu’à la fin de votre période d’essai. Votre abonnement est annulable
          à tout moment. La résiliation prend effet à la fin de la période de facturation.
        </div>
      )
    }

    if (isPriceDiscounted) {
      return (
        <div className={styles.nextSubtitle}>
          Vous allez recevoir un e-mail de confirmation. Votre abonnement est annulable à tout moment. La résiliation prend effet à la fin de la
          période de facturation.
        </div>
      )
    }

    return (
      <div className={styles.nextSubtitle}>
        Vous allez recevoir un e-mail de confirmation. Votre abonnement est annulable à tout moment. La résiliation prend effet à la fin de la période
        de facturation.
      </div>
    )
  }

  getPrevRouteForTracking = () => {
    // @dev tracking user registration path
    // user was redirected from 'Try now' CTA or clicked 'Create an account' at nav bar and validated registration
    if (
      (this.props.router.query?.utm_campaign && this.props.router.query.utm_campaign === "main_cta") ||
      this.props.router.query.utm_campaign === "createaccount"
    ) {
      return this.props.router.query.utm_campaign
    }

    if (this.props.router.query?.page === "offer") {
      const pathnameParts = this.props.router.pathname.split("/")

      return `offer:${pathnameParts[pathnameParts.length - 1]}`
    }

    return null
  }

  componentDidMount() {
    TrackingSegment.trackPage("success", {
      origin_page: "payment",
    })

    window.dataLayer &&
      window.dataLayer.push({
        event: "gtm.custom_event",
        event_name: "offer_subscription",
        event_category: "step_3",
        event_action: "subscription_sucess",
        event_label: `${this.props.productCode}`,
        payment_method: "CB",
        previous_page_path: `${this.getPrevRouteForTracking()}`,
      })
  }

  render() {
    const {
      customStyle,
      subscriptionEndAt,
      footer,
      isResiliation,
      nextTemplate,
      productName,
      subscriptionMessages,
      resiliationMessages,
      isFromTravelPage,
      warningMessage,
    } = this.props

    return nextTemplate ? (
      <div className={classNames(customStyle, styles.nextContainer)}>
        <img loading="lazy" src={check} />
        <div className={styles.nextTitle}>
          Félicitations !<br />
          Profitez dès à présent de <span>{productName}</span>
        </div>
        {this.generateConfirmationMessage()}
        {warningMessage && <div className={styles.warningMessage}>{textFormatter.styled(warningMessage.format, warningMessage.parts)}</div>}
        <div className={styles.nextButton} onClick={this.openInBrowser}>
          <Icon rootStyle={styles.linkIcon} type={IconTypes.externalLink} />
          &nbsp;Lancer Molotov
        </div>
      </div>
    ) : (
      <div className={customStyle}>
        <h3 className={styles.title}>{i18n.productConfirmation.title}</h3>
        <Separator customStyle={styles.separator} />
        <Icon type={IconTypes.checkmark} rootStyle={styles.iconRoot} />
        {isResiliation && (
          <div>
            {resiliationMessages &&
              Array.isArray(resiliationMessages.order) &&
              resiliationMessages.order.map((name, key) => {
                let message = resiliationMessages[name]

                if (message && name === "available_until") {
                  message = message.replace("%endDate%", dateHelper.format(subscriptionEndAt * 1000))
                }

                return (
                  <p key={key} className={styles.text}>
                    {message}
                  </p>
                )
              })}
          </div>
        )}
        {!isResiliation && (
          <div>
            {subscriptionMessages &&
              Array.isArray(subscriptionMessages.order) &&
              subscriptionMessages.order.map((name, key) => {
                return (
                  <p key={key} className={styles.text}>
                    {subscriptionMessages[name]}
                  </p>
                )
              })}
            {isFromTravelPage ? (
              <div>
                <p className={classNames(styles.text, styles.textMobile)}>
                  Pour profiter de Molotov sur mobile ou tablette, téléchargez l’application depuis l’App Store français ou le Google Play Store
                  français. Allez sur <Link to="/">www.molotov.tv</Link> pour en profiter depuis votre ordinateur.
                </p>
                <p className={classNames(styles.text, styles.textMobile)}>
                  Pour toute question, rendez-vous sur <Link to="https://aide.molotov.tv">aide.molotov.tv</Link>
                </p>
              </div>
            ) : null}
          </div>
        )}
        <Separator customStyle={styles.separator} />

        {footer}
      </div>
    )
  }
}
