import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"
import creditCardType from "credit-card-type"
import { RecurlyProvider, Elements } from "@recurly/react-recurly"

// Components
import InputSubmit from "components/InputSubmit"
import Loader from "components/Loader"
import ToolTip from "components/ToolTip"
import FormFooter from "./FormFooter"
import FormBottomMessage from "./FormBottomMessage"
import Separator from "components/Separator"
import TextInput from "components/TextInput/view"
import PaypalButton from "components/PaypalButton"
import SecurityCode from "./icons/SecurityCode"
import RecurlyForm from "./RecurlyForm"

// Helpers
import { getAssetURL } from "helpers/static"
import { getBrowserInfos } from "helpers/payment"

// Actions
import { postPayment, invalidCardInformations, resetCardErrors, setPaymentData } from "actions/mtvPay"
import { postDspPayment } from "actions/dspSub"
import apiAction from "helpers/apiAction"
import { open as openModal } from "actions/modal"

import consts from "consts"
import i18n from "consts/i18n"
import modalTypes from "containers/Modal/types"
import styles from "./index.css"

const TOOLTIP_TEXT = [i18n.payment.paymentForm.cvvTooltip]

// This is ugly since we're not supposed to hardcode those action names in our code but
// the API does not offer any mechanism to distinguish buttons and we need to do it to build
// this page (in order to know which button should be placed and styled as specified by mockups).
const PAYMENT_FORM_ONCLICK_IDENTIFIER = "payment_pay"
const PAYMENT_UPSELL_ONCLICK_IDENTIFIER = "payment_upsell"
const CANCEL_FORM_ONCLICK_IDENTIFIER = "cancel"
const visaImg = getAssetURL("image.pagePayment.visa")
const americanExpressImg = getAssetURL("image.pagePayment.americanExpress")
const mastercardImg = getAssetURL("image.pagePayment.mastercard")

@connect(state => ({
  appSettings: state.appSettings,
  frameless: state.appSettings.frameless,
}))
export default class PaymentFormDesktop extends Component {
  static propTypes = {
    amount: PropTypes.number,
    appSettings: PropTypes.object,
    buttonLabel: PropTypes.string, // used for edition of card
    dispatch: PropTypes.func.isRequired,
    equivalenceCode: PropTypes.string,
    formData: PropTypes.object,
    footer: PropTypes.object,
    frameless: PropTypes.bool,
    onCancel: PropTypes.func, // used for edition of card
    onSave: PropTypes.func,
    passwordError: PropTypes.object,
    paymentForm: PropTypes.object,
    paymentPending: PropTypes.bool,
    tracker: PropTypes.func.isRequired,
    paypalInfos: PropTypes.object,
    onSuccess: PropTypes.func,
    isRecurlyCard: PropTypes.bool,
  }

  state = {
    adyenLoaded: false,
    cardType: creditCardType.getTypeInfo(creditCardType.types.VISA),
    CVVTooltipOpenedViaTouch: false,
    password: "",
    showCVVTooltip: false,
    email: "",
  }

  static defaultProps = {
    paymentForm: {},
    tracker: consts.NoOp,
  }

  cardInputRef = React.createRef()
  holderName = "Molotov"

  getPaymentAction = () => {
    return this.props.paymentForm.interaction.actions[PAYMENT_FORM_ONCLICK_IDENTIFIER]
  }

  getCancelAction = () => {
    return this.props.paymentForm.interaction ? this.props.paymentForm.interaction.actions[CANCEL_FORM_ONCLICK_IDENTIFIER] : null
  }

  getUpsellAction = () => {
    this.props.dispatch(
      apiAction.executeAction({
        ...this.props.paymentForm.interaction.actions[PAYMENT_UPSELL_ONCLICK_IDENTIFIER],
        platform: this.props.appSettings.platform === consts.platform.apple ? consts.platformPayment.apple : consts.platformPayment.molotovpay,
      })
    )
  }

  useOtherCard = use => {
    this.setState({ hideCardForm: use })
  }

  onPasswordChange = password => {
    this.setState({ password })
  }

  isPasswordEmpty = () => {
    return !this.state.password
  }

  onForgotPassword = () => {
    this.props.dispatch(openModal(modalTypes.resetPassword, null, null, false))
  }

  onCancel = () => {
    const cancelAction = this.getCancelAction()

    if (cancelAction) {
      this.props.dispatch(apiAction.executeAction(cancelAction))
    }

    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onSubmit = e => {
    e.preventDefault()

    if (this.state.hideCardForm) {
      if (this.props.paymentPending || this.isPasswordEmpty()) {
        return
      }

      const paymentAction = this.getPaymentAction()
      if (this.props.paymentForm.is_dsp) {
        this.props.dispatch(postDspPayment(paymentAction, { password: this.state.password }, this.props.equivalenceCode))
      } else {
        this.props.dispatch(
          postPayment(
            paymentAction,
            {
              password: this.state.password,
              browser_info: getBrowserInfos(),
            },
            this.props.equivalenceCode
          )
        )
      }
      return
    } else {
      const {
        formData: { cardnumber, expiry, cvv },
        paymentPending,
      } = this.props

      if (paymentPending || !cardnumber.value || !cvv.value || !expiry.value) {
        return
      }

      let expiryMonth = ""
      let expiryYear = ""

      if (expiry.value) {
        expiryMonth = expiry.value.substr(0, 2)
        expiryYear = `20${expiry.value.substr(2, 4)}`
      }

      let isValid = true

      if (!this.validateCardNumber(cardnumber.value)) {
        isValid = false
        this.props.dispatch(
          setPaymentData("cardnumber", {
            error: true,
          })
        )
      }

      if (!this.validateSecurityCode(cvv.value)) {
        isValid = false
        this.props.dispatch(
          setPaymentData("cvv", {
            error: true,
          })
        )
      }

      if (!this.validateFinalExpiryDate(expiryMonth, expiryYear)) {
        isValid = false
        this.props.dispatch(
          setPaymentData("expiry", {
            error: true,
          })
        )
      }

      if (!isValid) {
        return
      }

      const encryptedData = this.cseInstance.encrypt({
        number: cardnumber.value,
        cvc: cvv.value,
        holderName: this.holderName,
        expiryMonth: expiryMonth,
        expiryYear: expiryYear,
        generationtime: new Date().toISOString(),
      })

      if (!encryptedData) {
        this.props.dispatch(invalidCardInformations())
        return
      }

      if (this.props.onSave) {
        this.props.onSave({ adyen_encrypted_card: encryptedData })
      } else {
        const paymentAction = this.getPaymentAction()
        this.props.dispatch(
          postPayment(
            paymentAction,
            {
              adyen_encrypted_card: encryptedData,
              browser_info: getBrowserInfos(),
            },
            this.props.equivalenceCode
          )
        )
      }
    }
  }

  /**************************/
  /******* Validators *******/
  /**************************/

  validateNumber = value => {
    return /^\d+$/.test(value) || !value.length
  }

  validateCardNumber(value = "") {
    // Takes the longest possible cardnumber for a given cardtype
    return this.validateNumber(value) && value.length <= this.state.cardType.lengths[this.state.cardType.lengths.length - 1]
  }

  validateSecurityCode(value = "", editing) {
    return (
      this.validateNumber(value) &&
      ((editing && value.length <= this.state.cardType.code.size) || (!editing && value.length === this.state.cardType.code.size))
    )
  }

  validateFinalExpiryDate(month, year) {
    return (
      this.validateNumber(month) &&
      this.validateNumber(year) &&
      month.length === 2 &&
      year.length === 4 &&
      month < 13 &&
      year >= new Date().getFullYear()
    )
  }

  validInput = input => {
    const {
      formData: { cardnumber, expiry, cvv },
    } = this.props

    switch (input) {
      case "cardnumber":
        // card length goes from 13 to 19
        return cardnumber.value.length > 12

      case "expiration":
        let expiryMonth = ""
        let expiryYear = ""

        if (expiry.value) {
          expiryMonth = expiry.value.substr(0, 2)
          expiryYear = `20${expiry.value.substr(2, 4)}`
          return this.validateFinalExpiryDate(expiryMonth, expiryYear)
        }

        return false

      case "cvv":
        return this.validateSecurityCode(cvv.value)

      default:
        return false
    }
  }

  creditCardType() {
    const creditcard = this.props.formData.cardnumber.value
    let amex = new RegExp("^3[47][0-9]{13}$")
    let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$")
    let cup1 = new RegExp("^62[0-9]{14}[0-9]*$")
    let cup2 = new RegExp("^81[0-9]{14}[0-9]*$")

    let mastercard = new RegExp("^5[1-5][0-9]{14}$")
    let mastercard2 = new RegExp("^2[2-7][0-9]{14}$")

    let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$")
    let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$")
    let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$")

    let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$")
    let jcb = new RegExp("^35[0-9]{14}[0-9]*$")

    if (visa.test(creditcard)) {
      return <img className={styles.typeImage} src={visaImg} />
    }
    if (amex.test(creditcard)) {
      return <img className={styles.typeImage} src={americanExpressImg} />
    }
    if (mastercard.test(creditcard) || mastercard2.test(creditcard)) {
      return <img className={styles.typeImage} src={mastercardImg} />
    }
    if (disco1.test(creditcard) || disco2.test(creditcard) || disco3.test(creditcard)) {
      return "Discover"
    }
    if (diners.test(creditcard)) {
      return "Diners"
    }
    if (jcb.test(creditcard)) {
      return "Jcb"
    }
    if (cup1.test(creditcard) || cup2.test(creditcard)) {
      return "China_union_pay"
    }
    return (
      <>
        <img className={styles.typeImage} src={visaImg} />
        <img className={styles.typeImage} src={mastercardImg} />
        <img className={styles.typeImage} src={americanExpressImg} />
      </>
    )
  }

  /**************************/
  /****** Input change ******/
  /**************************/

  formatCardNumber(cardNumber, cardType) {
    if (cardType) {
      var offsets = [].concat(0, cardType.gaps, cardNumber.length)
      var components = []

      for (var i = 0; offsets[i] < cardNumber.length; i++) {
        var start = offsets[i]
        var end = Math.min(offsets[i + 1], cardNumber.length)
        components.push(cardNumber.substring(start, end))
      }

      return components.join(" ")
    }

    return cardNumber
  }

  checkExpiryValues(month, year) {
    return this.validateNumber(month) && this.validateNumber(year) && month.length <= 2 && year.length <= 2
  }

  resetCardErrors = () => {
    if (get(this.props, "formData.error")) {
      this.props.dispatch(resetCardErrors())
    }
  }

  onCardNumberChange = value => {
    this.resetCardErrors()

    const strippedValue = value.replace(/\s/g, "")
    const cardType = (creditCardType(strippedValue) || []).shift() || creditCardType.getTypeInfo(creditCardType.types.VISA)

    this.setState({
      cardType,
    })

    if (this.validateCardNumber(strippedValue)) {
      this.props.dispatch(
        setPaymentData("cardnumber", {
          error: false,
          value: strippedValue,
          // We only format the card number when user enters new chars, not when he deletes ones
          // to avoid re-arranging digits by sequence if user is "fixing" one char.
          formattedValue:
            strippedValue.length >= this.props.formData.cardnumber.value.length ? this.formatCardNumber(strippedValue, cardType) : value,
        })
      )
    }
  }

  onExpiryChange = value => {
    this.resetCardErrors()

    const { expiry } = this.props.formData

    let expiryMonth = ""
    let expiryYear = ""

    // Handle special case of a user trying to remove the "/" in a such input value: "02/" (probably to change the month value)
    if (expiry.formattedValue && value === expiry.formattedValue.replace(/\/$/, "")) {
      // value was "02/" and user removed "/", which means that value is now "02". But since we consider that "/" is not really removable
      // by user (since it was written by us), we remove one more char, which leaves us with: "0". Another way to consider it is to say
      // that we only want to keep the first input value char (value[0]). Sumary: "02/" + DEL = "0".
      value = value[0]
    }

    // If a "/" is present, extract month and year from left and right content around the first "/"
    if (value.indexOf("/") !== -1) {
      const values = value.split("/")

      expiryMonth = values[0]
      expiryYear = values[1]
    }
    // No "/" present, assume user is still writing in input and take first 2 chars for month and full value for year
    else {
      expiryMonth = value.slice(0, 2)

      if (value.length > 2) {
        expiryYear = value.slice(2) // the value for year will be cleaned below
      }
    }

    // Clear year value to reduce it to 2 chars only
    if (expiryYear) {
      expiryYear =
        expiryYear.length >= 4 // If year is formatted as "2019" ...
          ? expiryYear.slice(-2) // ... takes only the last 2 chars of the year.
          : expiryYear.slice(0, 2) // else takes only the first 2 chars of the year because user has probably tried to enter more chars to an already correctly formatted year
    }

    let rawValue = expiryMonth + expiryYear

    if (this.checkExpiryValues(expiryMonth, expiryYear)) {
      this.props.dispatch(
        setPaymentData("expiry", {
          error: false,
          value: rawValue,
          formattedValue:
            rawValue === "" ? "" : expiryYear ? `${expiryMonth}/${expiryYear}` : expiryMonth.length === 2 ? `${expiryMonth}/` : expiryMonth,
        })
      )
    }
  }

  onSecurityCodeChange = value => {
    this.resetCardErrors()

    if (this.validateSecurityCode(value, true)) {
      this.props.dispatch(
        setPaymentData("cvv", {
          error: false,
          value,
        })
      )
    }
  }

  onCVVMouseEnter = () => {
    this.showCardTooltip()
  }

  onCVVMouseLeave = () => {
    this.hideCardTooltip()
  }

  onCVVTouchStart = () => {
    this.toggleCardTooltip()
  }

  hideCardTooltip = () => {
    this.setState({
      showCVVTooltip: false,
      CVVTooltipOpenedViaTouch: false,
    })
  }

  showCardTooltip = () => {
    this.setState({
      showCVVTooltip: true,
    })
  }

  toggleCardTooltip = () => {
    this.setState({
      showCVVTooltip: !this.state.showCVVTooltip,
      CVVTooltipOpenedViaTouch: true,
    })
  }

  getHeader = () => {
    const { formData, passwordError, paymentForm } = this.props

    const { hideCardForm } = this.state

    return (
      <>
        {paymentForm.error && <div className={classNames(styles.inlineErrorContainer, styles.errorLabel)}>{paymentForm.error.message}</div>}

        {!hideCardForm && formData.error && formData.error.message && (
          <div className={classNames(styles.formGroup, styles.errorLabel)}>{formData.error.message}</div>
        )}
        {hideCardForm && passwordError && passwordError.message && (
          <div className={classNames(styles.formGroup, styles.errorLabel)}>{passwordError.message}</div>
        )}

        <div className={classNames(styles.formGroup, styles.title)}>Payer par carte bancaire :</div>
      </>
    )
  }

  getFooter = () => {
    const { footer, paymentForm, paypalInfos } = this.props

    const updatedPaypalInfos = {
      ...paypalInfos,
      amount: paymentForm?.charge?.price,
      currency: paymentForm?.charge?.currency,
      ratePlanId: paymentForm?.charge?.rate_plan_id,
      isTBCC: paymentForm?.charge?.discount?.ratio === 1,
    }

    return (
      <>
        {!window.Android && !updatedPaypalInfos?.passwordFormShown && (
          <div className={styles.formGroup}>
            <div className={classNames(styles.formGroup, styles.title)}>Payer avec PayPal :</div>
            <PaypalButton {...updatedPaypalInfos} buttonStyle={styles.paypalButton} onCancel={this.onCancel} onSuccess={this.props.onSuccess} />
          </div>
        )}

        <FormFooter footerFormatter={paymentForm.footer_formatter} isDesktop={true} />
        {footer}
      </>
    )
  }

  getCVVElement = () => {
    const { CVVTooltipOpenedViaTouch, showCVVTooltip } = this.state
    return (
      <>
        <div
          className={styles.cardInfoDesktop}
          onMouseEnter={this.onCVVMouseEnter}
          onMouseLeave={this.onCVVMouseLeave}
          onTouchStart={this.onCVVTouchStart}
        />
        {showCVVTooltip && (
          <div className={styles.tooltipContainer}>
            <span className={styles.tooltipTriangle}>◀</span>
            <ToolTip
              onCloseClick={CVVTooltipOpenedViaTouch ? this.hideCardTooltip : null}
              imageSVG={<SecurityCode />}
              textArray={TOOLTIP_TEXT}
              rootStyle={styles.tooltip}
            />
          </div>
        )}
      </>
    )
  }

  submitRecurly = token => {
    if (this.props.onSave) {
      this.props.onSave({ token: token.id })
    } else {
      const paymentAction = this.getPaymentAction()
      this.props.dispatch(
        postPayment(
          paymentAction,
          {
            token: token.id,
            browser_info: getBrowserInfos(),
          },
          this.props.equivalenceCode
        )
      )
    }
  }

  submit3dSecureRecurly = (threeToken, token) => {
    //three_ds_result_token
    if (this.props.onSave) {
      this.props.onSave({ "3ds_action_result_token_id": threeToken.id, token: token.id })
    } else {
      const paymentAction = this.getPaymentAction()
      this.props.dispatch(
        postPayment(
          paymentAction,
          {
            three_ds_result_token: threeToken.id,
            token: token.id,
            browser_info: getBrowserInfos(),
          },
          this.props.equivalenceCode
        )
      )
    }
  }

  constructor(props) {
    super(props)

    // dynamically import the sdk or the pre-commit will fail
    // (the lib tries to use navigator and crashes server-side)
    import("adyen-cse-js").then(adyenEncrypt => {
      this.cseInstance = adyenEncrypt.createEncryption(consts.config.adyenPublicKey, {})
      this.setState({ adyenLoaded: true })
    })
  }

  componentDidMount() {
    this.props.tracker("payment", this.props.equivalenceCode)
    const paymentCard = get(this.props.paymentForm, "interaction.buttons", []).find(button => button.title === "Utiliser une nouvelle carte")
    if (paymentCard) {
      this.setState({ hideCardForm: true })
    } else {
      this.setState({ hideCardForm: false })
    }
  }

  getSnapshotBeforeUpdate() {
    if (
      document.activeElement &&
      document.activeElement === this.cardInputRef.current &&
      // Save cursor position only if the new formatted value is different than the current input value
      this.cardInputRef.current.value !== this.props.formData.cardnumber.formattedValue
    ) {
      return {
        start: this.cardInputRef.current.selectionStart,
        end: this.cardInputRef.current.selectionEnd,
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.adyenLoaded && this.state.adyenLoaded && this.cardInputRef.current) {
      this.cardInputRef.current.focus()
    }

    if (snapshot && document.activeElement && document.activeElement.setSelectionRange && document.activeElement === this.cardInputRef.current) {
      // If the char just before the supposed cursor position is a " ", move one char further since there is
      // no chance that the user wrote this char himself
      if (this.props.formData.cardnumber.formattedValue.slice(Math.max(snapshot.end - 1, 0)).charAt(0) === " ") {
        snapshot.start = snapshot.end = snapshot.start + 1
      }

      this.cardInputRef.current.setSelectionRange(snapshot.start, snapshot.end)
    }
  }

  render() {
    const { formData, frameless, passwordError, paymentForm, paymentPending, isRecurlyCard } = this.props

    const { adyenLoaded, hideCardForm, password } = this.state

    if (!adyenLoaded) {
      return <Loader />
    }

    let buttons, paymentButton, paymentCard, paymentUpsell
    if (paymentForm.interaction) {
      buttons = paymentForm.interaction.buttons
      paymentButton = apiAction.findButtonByOnClick(buttons, PAYMENT_FORM_ONCLICK_IDENTIFIER)
      paymentCard = buttons.find(button => button.title === "Utiliser une nouvelle carte")
      paymentUpsell = apiAction.findButtonByOnClick(buttons, PAYMENT_UPSELL_ONCLICK_IDENTIFIER)
    }

    const isRecurly = paymentForm.platform === "recurly" || isRecurlyCard

    if (isRecurly) {
      return (
        <RecurlyProvider publicKey={consts.config.recurlyKey}>
          <Elements>
            <RecurlyForm
              formData={formData}
              submitRecurly={this.submitRecurly}
              submit3dSecureRecurly={this.submit3dSecureRecurly}
              header={this.getHeader}
              footer={this.getFooter}
              buttonLabel={paymentButton.title}
              //discount={discount}
              paymentForm={paymentForm}
              paymentPending={paymentPending}
              //fromOptionPage={fromOptionPage}
              paymentButton={paymentButton}
              cvvElement={this.getCVVElement}
            />
          </Elements>
        </RecurlyProvider>
      )
    }

    return (
      <form className={styles.desktopPaymentForm} onSubmit={this.onSubmit} data-test="form-payment">
        {this.getHeader()}

        {paymentCard && (
          <div className={styles.paymentCard}>
            <div className={styles.creditCard} onClick={() => this.useOtherCard(true)}>
              <span>{paymentCard.subtitle}</span>
              <span className={styles.paymentType}>
                <input type="radio" readOnly checked={hideCardForm} />
              </span>
            </div>
            {hideCardForm && (
              <div className={styles.cardChange} onClick={() => this.useOtherCard(false)}>
                <span>{paymentCard.title}</span>
              </div>
            )}
          </div>
        )}

        {hideCardForm && (
          <div
            className={classNames(styles.formGroup, styles.formPassword, styles.formMargin, {
              [styles.formPasswordError]: passwordError && passwordError.message,
            })}
          >
            <TextInput
              inputStyles={classNames(styles.inputPass, { [styles.inputPassError]: passwordError && passwordError.message })}
              label="Mot de passe"
              type="password"
              value={password}
              onChange={e => this.onPasswordChange(e.target.value)}
            />
            <span onClick={this.onForgotPassword}>Oublié ?</span>
          </div>
        )}
        {!hideCardForm && (
          <div className={styles.formGroup}>
            <TextInput
              inputStyles={classNames(styles.inputDesktop, { [styles.validInput]: this.validInput("cardnumber") })}
              label="Numéro de carte"
              type="text"
              value={formData.cardnumber.formattedValue}
              onChange={e => this.onCardNumberChange(e.target.value)}
              inputRef={this.cardInputRef}
              autoComplete="cc-number"
            />
            <span className={styles.inputCard}>
              <span>{this.creditCardType()}</span>
              {paymentCard && <input type="radio" readOnly checked={!hideCardForm} />}
            </span>
          </div>
        )}
        {!hideCardForm && (
          <div className={styles.inlineFormGroup}>
            <div className={styles.inlineFormElement}>
              <TextInput
                inputStyles={classNames(styles.inputDesktop, { [styles.validInput]: this.validInput("expiration") })}
                label="Date d’expiration"
                type="text"
                value={formData.expiry.formattedValue}
                onChange={e => this.onExpiryChange(e.target.value)}
                autoComplete="cc-exp"
                error={formData.expiry.formattedValue.length === 5 && !this.validInput("expiration")}
                errorMessage="Date invalide"
              />
            </div>
            <div className={classNames(styles.inlineFormElement, styles.lastInlineElement)}>
              <div className={styles.cvcInput}>
                <TextInput
                  inputStyles={classNames(styles.inputDesktop, { [styles.validInput]: this.validInput("cvv") })}
                  label="Code de sécurité"
                  type="text"
                  value={formData.cvv.value}
                  onChange={e => this.onSecurityCodeChange(e.target.value)}
                  autoComplete="cc-csc"
                />
                {this.getCVVElement()}
              </div>
            </div>
          </div>
        )}

        <FormBottomMessage messageFormatter={paymentForm.message_bottom_formatter} />

        {paymentUpsell && frameless ? (
          <div className={styles.upSellContainer}>
            <Separator />
            <div onClick={this.getUpsellAction} className={styles.upSell}>
              <span>{paymentUpsell.subtitle}</span>
              <span>
                <b>{paymentUpsell.title}</b>
              </span>
            </div>
            <Separator />
          </div>
        ) : null}

        <div className={classNames(styles.formGroup, styles.submitButton)}>
          <InputSubmit
            disabled={
              (hideCardForm && !password) ||
              (!hideCardForm && (!this.validInput("cardnumber") || !this.validInput("expiration") || !this.validInput("cvv")))
            }
            customStyle={classNames(InputSubmit.types.yellowFull, styles.submitButtonValue, { [styles.buttonPending]: paymentPending })}
            value={paymentButton ? paymentButton.title : "Payer"}
          />
        </div>

        {this.getFooter()}
      </form>
    )
  }
}
