import endpoints from "./endpoints"
import routes from "./routes"
import assets from "./assets"
import i18n from "./i18n"

const appStoreId = "1053134780"
const deeplinkScheme = "molotov://"

export default {
  config: global.mltvConf,
  endpoints,
  routes,
  assets,
  i18n,
  twitterUser: "@molotovtv",
  externalLink: {
    landing: "https://www.molotov.tv",
    welcome: "https://welcome.molotov.tv",
    extra: "https://extra.molotov.tv/",
    extended: "https://extended.molotov.tv/",
    facebook: "https://www.facebook.com/molotovapp",
    twitter: "https://twitter.com/molotovtv",
    instagram: "https://www.instagram.com/molotov",
    legalNotice: "http://www.molotov.tv/legal",
    support: "http://aide.molotov.tv/",
    supportForm: "http://aide.molotov.tv/pages/contact",
    firebasePath: "https://e7665.app.goo.gl/",
    blog: "https://medium.com/@molotovtv",
    adjustDownload: "https://app.adjust.com/10c8dwai_102t6v89?fallback=https%3A%2F%2Fwww.molotov.tv%2Fdownload",
    appStore: "https://apps.apple.com/fr/app/molotov-tv-en-direct-replay/id1053134780",
    googlePlay: "https://play.google.com/store/apps/details?id=tv.molotov.app&hl=fr",
    series: "https://serie.molotov.tv/",
    seriesHotd: "https://serie.molotov.tv/serie/got-house-of-the-dragon",
    seriesCdm: "https://serie.molotov.tv/serie/coupe-du-monde-de-football",
    seriesTwd: "https://serie.molotov.tv/serie/the-walking-dead",
    device: {
      apple: "http://www.apple.com/fr/tv/entertainment/molotov",
      lg: "http://lg.com/fr/molotov/molotov",
      samsung: "http://www.samsung.com/fr/tv-molotov/",
      nvidia: "https://www.nvidia.fr/shield/s/apps/",
      sony: "https://www.sony.fr/electronics/televiseur-fonctions-intelligentes-applications-et-internet",
      chromeCast: "https://store.google.com/product/chromecast_google_tv",
      panasonic: "https://www.panasonic.com/fr/consumer/televiseurs/tv-oled-4k-pro-hdr.html",
      oculus: "https://www.oculus.com/experiences/go/2152219778178495/",
    },
    founders: {
      jd: "https://fr.wikipedia.org/wiki/Jean-David_Blanc",
      pl: "https://fr.wikipedia.org/wiki/Pierre_Lescure",
      jm: "https://www.linkedin.com/in/jm-denoual-3999862",
    },
    welcomeKitApplication: "https://molotov-tv.welcomekit.co/jobs/candidatures-spontanees",
    amazonFireStick: "https://www.amazon.fr/dp/B077ZCKJZW/",
    paypalAccount: "https://www.paypal.com/myaccount/summary",
    press: "https://press.cloud-01.molotov.tv",
    football2024: "https://www.foot-europe-2024.molotov.tv/",
    olympics2024: "https://www.jeux-olympiques-france-televisions.molotov.tv/",
  },
  inappLink: "inapp://",
  appStoreId,
  deeplinkScheme,
  deeplinkPath: `${deeplinkScheme}www.molotov.tv`,
  contact: "hello@molotov.tv",
  smallViewportWidth: 640,
  mediumViewportWidth: 800,
  largeViewportWidth: 960,
  minPasswordLength: 6,
  maxPasswordLength: 127,
  maxDevicesCount: 5,
  molotovAgent: {
    appBuild: 1,
    appId: "customer_area",
    manufacturer: "",
    model: "",
    brand: "",
    serial: "",
    type: "",
    os: "",
    apiVersion: 8,
  },
  devicesType: {
    desktop: "desktop",
    tv: "tv",
    phone: "phone",
    tablet: "tablet",
    watch: "watch",
  },
  platform: {
    web: "web",
    apple: "apple",
    android: "android",
  },
  frameType: {
    basic: "basic",
    mobile: "mobile",
    tv: "tv",
  },
  platformPayment: {
    amazon: "amazon",
    apple: "apple",
    googlePlay: "google_play",
    molotovpay: "molotovpay",
  },
  productCode: {
    free: "FREE",
    ocs: "OCS",
    cinePlus: "CINE_PLUS",
    extended: "EXTENDED",
    option100H: "OPTION_100H",
    option100HApple: "MOLOTOV_TV_100H_1",
    extendedApple: "MOLOTOV_TV_EXTENDED2",
  },
  productEquivalenceCode: {
    free: "FREE",
    ocs: "OCS",
    cinePlus: "CINE_PLUS",
    extended: "EXTENDED",
    option100H: "OPTION_100H",
    theMatch: "THEMATCH_2018",
    rmcSport: "RMC_SPORT",
    adultSwim: "ADULT_SWIM",
    africanBasic: "AFRICAIN_BASIC",
    africanPrenium: "AFRICAIN_PREMIUM",
    starzplay: "STARZ",
    molotovExtra: "MOLOTOV_EXTRA",
    molotovExtra1Y: "MOLOTOV_EXTRA_1_AN",
    newFunnel: "COPT",
  },
  programs: {
    gotPrograms: ["4382", "1036", "64634", "83151", "43053", "45895", "45898", "64634"],
    mythoPrograms: ["183963"],
    pblvSecondaryPrograms: ["29824", "30269", "27175", "36943", "37011", "36665"],
    pblvMainProgram: {
      channel_id: "49",
      id: "726",
      type: "program",
      slug: "plus-belle-la-vie",
    },
    adultSwimPrograms: [
      {
        name: "robotChicken",
        slug: "robot-chicken",
        id: "7772",
      },
      {
        name: "finalSpace",
        slug: "final-space",
        id: "174346",
      },
      {
        name: "rickEtMorty",
        slug: "rick-et-morty",
        id: "16899",
      },
      {
        name: "archer",
        slug: "archer",
        id: "16900",
      },
      {
        name: "aquaTeenHungerForce",
        slug: "aqua-teen-hunger-force",
        id: "173853",
      },
      {
        name: "samuraiJack",
        slug: "samurai-jack",
        id: "159316",
      },
      {
        name: "squidbillies",
        slug: "squidbillies",
        id: "173992",
      },
      {
        name: "harveyBirdmanAttorneyAtLaw",
        slug: "harvey-birdman-attorney-at-law",
        id: "172835",
      },
      {
        name: "eagleheart",
        slug: "eagleheart",
        id: "180702",
      },
      {
        name: "moralOrel",
        slug: "moral-orel",
        id: "174105",
      },
      {
        name: "metalocalypse",
        slug: "metalocalypse",
        id: "173983",
      },
      {
        name: "theVentureBros",
        slug: "the-venture-bros",
        id: "180058",
      },
      {
        name: "mrPickles",
        slug: "mr-pickles",
        id: "174719",
      },
      {
        name: "primal",
        slug: "primal",
        id: "183672",
      },
    ],
    toonamiPrograms: [
      {
        name: "dragonBallSuper",
        slug: "dragon-ball-super-version-integrale",
        id: "37617",
      },
    ],
    westworldProgram: ["33988"],
    adultSwimChannel: {
      id: "236",
      name: "adultSwim",
      slug: "adult-swim",
    },
    toonamiChannel: {
      id: "139",
      name: "toonami",
      slug: "toonami",
    },
    unSiGrandSoleilProgram: "56177",
  },
  api: {
    errorCodes: {
      INTERNAL_ERROR: 0,
      ACCESS_DENIED: 1,
      TOKEN_EXPIRED: 2,
      BACK_API_ERROR: 3,
      SCREEN_LIMIT_EXCEEDED: 4,
      LOGOUT_MANDATORY: 5,
      DEFAULT: 100,
      CONVERSION_MANDATORY: 101,
      USER_BLOCKED: 102,
      INVALID_CREDENTIALS: 103,
      DEVICE_BLOCKED: 104,
      UPDATE_MANDATORY: 105,
      UPDATE_OPTIONAL: 106,
      BOOKMARK_QUOTA_EXCEEDED: 107,
      BOOKMARK_INVALID_RIGHTS: 108,
      BOOKMARK_DISABLED: 109,
      LOCATION_UNAUTHORIZED: 120,
      ASSET_ACCESS_DENIED: 121,
      OCS_NO_SUB: 122,
      SIGNUP_MISSING_FIELDS: 123,
      SUB_DSP_FAILED: 125,
    },
  },
  deepLinkTypes: {
    PROGRAM: "program",
    EPISODE: "episode",
  },
  NoOp: function() {},
  emptyArray: [],
  emptyObject: {},
  tileType: {
    broadcast: "broadcast",
    channel: "channel",
    episode: "episode",
    feature: "feature",
    offer: "offer",
    person: "person",
    program: "program",
    record: "record",
    video: "video",
    vod: "vod",
  },
  deeplinkDownloadClass: "dl-action",
  deeplinkOpenClass: "open-action",
  cache: {
    defaultCacheMaxAge: 60 * 5, // 5mn
  },
  slugs: {
    live: "en-ce-moment",
    next: "juste-apres",
    replay: "en-replay",
    recommendation: "populaire-sur-molotov",
    tonight_1: "ce-soir",
    tonight_2: "deuxieme-partie-de-soiree",
    "new-recommendation": "nouveaux-programmes",
  },
  localStorageKey: {
    lastEmailLogged: "last_email_logged",
    cookieSettings: "mtv_cookieSettings",
  },
  sessionStorageKey: {
    registerFollowUpId: "registerFollowUpId",
    programOfferToSubscribe: "program_offer_to_subscribe",
  },
  cookieNames: {
    userTracking: "registerFollowUp",
    referrerFromLanding: "referrerFromLanding",
    clickId: "clickid",
  },
  datadog: {
    name: "mtv-landing",
    apiKey: "21e3915936692d082a7259ccb1ba887c",
    endpoint: "https://http-intake.logs.datadoghq.com/v1/input/",
  },
  segmentKeys: {
    onMyTvPageViewed: "on_my_tv_page_viewed",
  },
  socialImagesSize: {
    width: 512,
    height: 1004,
  },
  newSocialImagesSize: {
    width: 600,
    height: 314,
  },

  apiActionTypes: {
    action_feedback: "action_feedback",
    api_action: "api_action",
    close: "close",
    forward: "forward",
    login_with_password: "login_with_password",
    navigation: "navigation",
    onClick: "on_click",
    promocodeSuccess: "promocode_success",
    redirect: "redirect",
    reset: "landing_reset",
    setProduct: "set_product",
    showAnimation: "show_animation",
    show_dialog: "show_dialog",
    show_interstitial: "show_interstitial",
    submit: "submit",
    update_pin: "update_pin",
    payment_step: "payment_step",
    buy: "buy",
    payment_3ds: "payment_3ds",
    recurly_3ds: "recurly_3ds",
  },

  headers: {
    xActionContext: "X-Action-Context",
    parentAgent: "X-Molotov-Parent-Agent",
  },

  templateMap: {
    action_feedback: "action_feedback",
    catalog: "catalog",
    product_catalog: "product_catalog",
    programs: "programs",
    payment_card: "payment_card",
    payment_password: "payment_password",
    web_view: "web_view",
    gift_claim: "gift_claim",
    gift_offer: "gift_offer",
    product_subscribe: "product_subscribe",
    promocode_success: "promocode_success",
    gift_success: "gift_success",
    gift_list: "gift_list",
    promocode_claim: "promocode_claim",
    reset_password: "reset_password",
    generic: "generic",
    external_link: "external_link",
    payment_before_pay_periodicity_selection: "payment_before_pay_periodicity_selection",
  },

  privacy: {
    acceptTracking: "acceptTracking",
    acceptAds: "acceptAds",
    gtmAdBucket: "GTM-K2FL6H9",
    gtmTrackBucket: "GTM-T9GW69",
    gtagId: "AW-10852192232",
    gtagRegistration: "AW-10852192232/Zzy4CM31xKMDEOif3bYo",
    gtagSubScribe: "AW-10852192232/djWiCIzYxKMDEOif3bYo",
  },

  // "slug_seo" field
  footerChannels: ["france-2", "france-3", "france-5", "c8", "bfm-tv", "itele", "arte"],
  footerCategories: ["movies", "tvshows", "sport"],
  onMolotovChannels: ["tf1", "france-2", "france-3", "france-5", "m6", "arte", "c8", "w9", "tmc", "bfm-tv"],
  travelChannels: [
    // slug_seo field
    "tf1",
    "france-2",
    "france-3",
    "france-5",
    "m6",
    "arte",
    "c8",
    "w9",
    "tmc",
    "nt-1",
    "nrj-12",
    "lcp-public-senat",
    "france-4",
    "bfm-tv",
    "itele",
    "cstar",
    "gulli",
    "france-o",
    "hd1",
    "l-equipe",
    "6ter",
    "numero-23",
    "rmc-decouverte",
    "lci",
  ],
  apple: {
    prod: {
      redirectURI: "https://www.molotov.tv/account/profile",
    },
    staging: {
      redirectURI: "https://www-staging.molotov.tv/account/profile",
    },
    dev: {
      redirectURI: "https://www-dev.molotov.tv/account/profile",
    },
  },
}
