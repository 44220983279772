import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.css"
import { exposeStyles } from "helpers/components"

import HeaderBackground from "components/HeaderBackground"

const ContentHeader = ({ title, subTitle, customStyle, image, imageAlt, imageLink, hasBackground, headerCustomStyle, headerSubtitleStyle }) => (
  <div className={styles.root}>
    {(hasBackground !== void 0 ? hasBackground : true) && <HeaderBackground customStyle={headerCustomStyle} />}
    <div className={styles.content} style={customStyle}>
      <h1 className={styles.headerTitle}>{title}</h1>
      <div className={classNames(styles.headerSubtitle, headerSubtitleStyle)}>{subTitle}</div>
      {image && (
        <p className={styles.headerImage}>
          <a href={imageLink} target="_blank">
            <img loading="lazy" src={image.src} srcSet={image.srcSet} width={image.width} height={image.height} alt={imageAlt} />
          </a>
        </p>
      )}
    </div>
  </div>
)

ContentHeader.propTypes = {
  title: PropTypes.element,
  subTitle: PropTypes.element,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  imageLink: PropTypes.string,
  customStyle: PropTypes.object,
  hasBackground: PropTypes.bool,
  headerCustomStyle: PropTypes.string,
  headerSubtitleStyle: PropTypes.string,
}

export default exposeStyles(ContentHeader, styles)
