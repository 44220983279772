import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
// import { Route } from "react-router-dom"
import { throttle } from "lodash"

import RouterSwitch from "components/RouterSwitch/view"
import { DidomiSDK } from "@didomi/react"
import { toggleDidomiModal } from "actions/privacy"
import CookieBanner from "components/CookieBanner"
import Modal from "containers/Modal"
import modalTypes from "containers/Modal/types"
// import ChromecastFooter from "containers/PageOPChromecast/footer"

import { setAppSettings } from "actions/appSettings"
import { hideNav, setWindowWidth } from "actions/ui"

import registerTracking from "managers/registerTrackingCookieManager"

import { selectQuery } from "selectors/location"
import selectAuthentication from "selectors/authentication"
import selectAccount from "selectors/account"

import routeHelper from "helpers/route"

import consts from "consts"
import styles from "./index.css"

@connect((state, ownProps) => ({
  appSettingsSet: state.appSettings.set,
  displayCookieBanner: state.privacy.displayCookieBanner && state.modal.type !== modalTypes.cookie,
  frameless: state.appSettings.frameless,
  modal: state.modal,
  query: selectQuery(ownProps),
  toggleDidomiBanner: state.privacy.toggleDidomiBanner,
  authentication: selectAuthentication(state),
  userAccount: selectAccount(state),
}))
export default class AppWrapper extends Component {
  static propTypes = {
    appSettingsSet: PropTypes.bool,
    dispatch: PropTypes.func,
    displayCookieBanner: PropTypes.bool,
    frameless: PropTypes.bool,
    location: PropTypes.object,
    modal: PropTypes.object,
    query: PropTypes.object,
    serverCookies: PropTypes.string,
    toggleDidomiBanner: PropTypes.bool,
    authentication: PropTypes.object,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    modal: {},
  }

  privacySettingsApplied = false

  onWindowResize = throttle(() => {
    this.props.dispatch(setWindowWidth(window.innerWidth))
  }, 500)

  ShouldHideCookieBanner = () => {
    const { location } = this.props
    return location.pathname.includes(consts.routes.privacy) || location.pathname.includes(consts.routes.cookies)
  }

  constructor(props) {
    super(props)

    const { appSettingsSet, query } = props

    if (!appSettingsSet) {
      props.dispatch(
        setAppSettings({
          frameless: !!query.frameless,
          // TODO: frametype (sent by android apps) and frameType (sent by ios)
          // are both supported
          // wait for iOS to use frameType
          frameType: query.frameType || query.frametype || routeHelper.getFrameTypeFromQuery(query) || consts.frameType.basic,
          platform: query.platform || consts.platform.web,
          device: query.device || "",
          molotovAgent: query["molotov-agent"],
        })
      )
    }

    if (query.hideNav === "true") {
      props.dispatch(hideNav())
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize)
    this.onWindowResize()
    registerTracking.check(this.props.query)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      setTimeout(() => {
        global.scrollTo && window.location.hash === "" && global.scrollTo(0, 0)
      }, 100)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize)
  }

  render() {
    const didomiConfig = {
      app: {
        name: "Didomi",
        apiKey: "4d777667-a645-4507-9785-87ffeb264d39",
        noticeId: "JG9Dweag",
        vendors: {
          iab: {
            all: true,
            version: 2,
          },
        },
      },
      user: {
        organizationUserId: this.props?.userAccount?.id,
      },
      notice: {
        position: "popup",
      },
      languages: {
        enabled: ["fr", "en"],
        default: "fr",
      },
    }
    const { displayCookieBanner, frameless, modal } = this.props
    return (
      <div className={styles.root}>
        <RouterSwitch groupName="rootRoutes" serverCookies={this.props.serverCookies} />
        {
          <DidomiSDK
            config={didomiConfig}
            gdprAppliesGlobally={true}
            sdkPath="https://sdk.privacy-center.org/"
            onReady={didomi => {
              const { authentication } = this.props
              if (!authentication.isAuthenticated || window.location.pathname !== "/") {
                didomi.notice.hide()
              }
              let userStatus = didomi.getUserConsentStatus("cookies", 1)
              if (authentication.isAuthenticated && !userStatus) {
                this.props.dispatch(toggleDidomiModal())
              }
            }}
            onNoticeHidden={() => {
              const { authentication } = this.props
              if (authentication.isAuthenticated) {
                this.props.dispatch(toggleDidomiModal())
              }
            }}
          />
        }
        {displayCookieBanner && !this.ShouldHideCookieBanner() && !frameless && <CookieBanner />}
        {/* {!frameless && <Route path={consts.routes.opChromecast} component={ChromecastFooter} />} */}
        {modal.open && <Modal type={modal.type} data={modal.data} action={modal.action} closeOnDimmerClick={modal.closeOnDimmerClick} />}
      </div>
    )
  }
}
