export default {
  // Authenticated pages
  account: "/account",
  devices: "/account/devices",
  offer: "/account/group-offers/:equivalenceCode",
  offers: "/account/products",
  parentalControl: "/account/parental-control",
  payment: "/account/payment",
  paypal: "/account/payment/sandbox/paypal",
  profile: "/account/profile",
  storage: "/account/storage",
  tv: "/account/tv",
  unsubscribeStep1: "/account/unsubscribe/:subscriptionId",
  unsubscribeStep2: "/account/unsubscribe/step2/:subscriptionId",
  purgeBkm: "/account/purge",
  notifications: "/account/notifications",
  giftcode: "/gift/code",
  giftbuy: "/gift/buy",
  promoCode: "/promo",
  newFunnel: "subscribe/COPT",

  // Public pages
  default: "/",
  about: "/about",
  beta: "/beta",
  boulanger: "/boulanger",
  campaign: "/campaign",
  careers: "/careers",
  category: "/:locale/cat/:categoryId/:categorySlug",
  categoryPage: "/:locale/cat/:categoryId/:categorySlug/page/:page",
  changePassword: "/change-password/:token",
  changeEmail: "/change-email/:token",
  channel: "/:locale/c/:channelId/:channelSlug",
  channelReplay: "/:locale/r/:channelId/:channelSlug-replay",
  cookies: "/legal/cookies",
  cookiesSettings: "/legal/cookies/preferences",
  createAccount: "/create-account/:token",
  deeplink: "/deeplink",
  discoverProduct: "/account/products#offer_MOLOTOV_EXTRA",
  download: "/download",
  error: "/error",
  genre: "/:locale/cat/:categoryId/:categorySlug/:genreId/:genreSlug",
  genrePage: "/:locale/cat/:categoryId/:categorySlug/:genreId/:genreSlug/page/:page",
  giftcongrats: "/gift/congrats",
  giftlist: "/gift/list",
  giftoptions: "/gift/options",
  gtu: "/legal/terms",
  hardware: "/devices",
  legal: "/legal",
  linktv: "/link",
  mentions: "/legal/notice",
  oauth: "/oauth/dialog",
  ocsAuth: "/ocs-auth",

  opApp: "/source-tv-installation",
  opChromecast: "/chromecast",
  person: "/:locale/pers/:personId/:personSlug?",
  lettreTf1: "/lettre-tf1",
  press: "/presse",
  pressRelease: "/press",
  privacy: "/legal/privacy",
  products: "/offers",
  program: "/:locale/p/:programId-:channelId/:programSlug?",
  publicOffer: "/group-offers/:equivalenceCode",
  resetPassword: "/reset-password",
  resetEmail: "/reset-email",
  seoHome: "/:locale/programme-tv",
  movies: "/:locale/cat/1/movies",
  seoHomeSection: "/:locale/programme-tv/:section",
  signup: "/account/profile?signup=true",
  signupInformations: "/account/profile?signupInformations=true",
  subCategory: "/:locale/scat/:categoryId-:subCategoryId/:subCategorySlug",
  subscribe: "/subscribe/:equivalenceCode",
  subscribePage: "/subscribe/:equivalenceCode?page=offer",
  theMatch: "/thematch",
  travel: "/tv-francaise-etranger",
  landingStrapi: "/s/:url",
  travelProduct: "/tv-francaise-etranger/:equivalenceCode",
  tvPublic: "/tv",
  unsubscribeEmail: "/notificationspreferences/:userId/:hashedEmail/:emailType",

  // device pages
  deviceAmazonAlexa: "/tv-amazon-echo-show",
  deviceAndroid: "/tv-android",
  deviceAppleTv: "/tv-appletv",
  deviceChromecast: "/tv-chromecast",
  deviceDesktop: "/tv-ordinateur",
  deviceGoogleAssistant: "/tv-google-nest-hub",
  deviceHuawei: "/tv-huawei-appgallery",
  deviceIphone: "/tv-iphone-ipad",
  deviceLg: "/tv-smarttv-lg",
  devicePanasonic: "/tv-panasonic",
  deviceSamsung: "/tv-smarttv-samsung",
  deviceSourcePanasonic: "/source-tv-panasonic",
  deviceXbox: "/tv-xbox",
  deviceXiaomi: "/tv-mitvstick",
  deviceHisense: "/tv-hisense",
  african: "/bouquet-africain",
  got: "/game-of-thrones",
  mytho: "/mytho",
  westworld: "/ocs/westworld",
  ocs: "/ocs",
  rmc: "/rmc-sport",
  adultSwim: "/adult-swim-warner-tv-next",
  rickMorty: "/rick-et-morty",
  starzplay: "/starzplay",
  tvDirect: "/regarder-tv-direct",
  tvFreeChannels: "/regarder-tv-gratuite",
  tvReplay: "/regarder-tv-replay",
  tvStreaming: "/regarder-tv-streaming",
  cguPdf: "/terms.pdf",
  privacyPdf: "/privacy.pdf",
  cookiesPdf: "/cookies.pdf",
  docs: [
    {
      path: "/docs/legal/reglement_jeu_molotov_on_instagram_fev_2021.pdf",
      asset: "doc.legal.reglement_molotov_on_instagram",
    },
    {
      path: "/docs/legal/reglement_jeu_molotov_x_manon_mars_2021.pdf",
      asset: "doc.legal.reglement_molotov_x_manon",
    },
    {
      path: "/docs/legal/reglement_jeu_hoversmash_x_molotov_mars_2021.pdf",
      asset: "doc.legal.reglement_molotov_x_hoversmash",
    },
    {
      path: "/docs/legal/reglement_jeu_Julien_Chieze_X_Molotov_mars_2021.pdf",
      asset: "doc.legal.reglement_molotov_x_julien_chieze",
    },
    {
      path: "/docs/legal/reglement_jeu_CAMKEV_X_Molotov_mars_2021.pdf",
      asset: "doc.legal.reglement_molotov_x_kev",
    },
    {
      path: "/docs/legal/REGLEMENT_DE_JEU_GOT_10_ans_instagram_Molotov_avril_2021.pdf",
      asset: "doc.legal.reglement_got_10_ans_insta",
    },
    {
      path: "/docs/legal/reglement_de_jeu_tu_as_la_ref_Version_Instagram_Molotov_mai_2021.pdf",
      asset: "doc.legal.reglement_tu_as_la_ref",
    },
    {
      path: "/docs/legal/Tom_Jerry_X_Molotov_mai_2021.pdf",
      asset: "doc.legal.reglement_tom_et_jerry",
    },
  ],
  inBrowserUrl: {
    dev: "https://app-dev.molotov.tv",
    staging: "https://app-staging.molotov.tv",
    prod: "https://app.molotov.tv",
  },
}
