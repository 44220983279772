import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { CardNumberElement, CardMonthElement, CardYearElement, CardCvvElement, ThreeDSecureAction, useRecurly } from "@recurly/react-recurly"

import FormBottomMessage from "./FormBottomMessage"
import InputSubmit from "components/InputSubmit"

import { getAssetURL } from "helpers/static"

import i18n from "consts/i18n"
import styles from "./index.css"
import { getAPIEnv } from "../../../helpers/api"
import consts from "consts"

const visaImg = getAssetURL("image.pagePayment.visa")
const americanExpressImg = getAssetURL("image.pagePayment.americanExpress")
const mastercardImg = getAssetURL("image.pagePayment.mastercard")

const RecurlyForm = ({
  formData,
  footer,
  header,
  submitRecurly,
  submit3dSecureRecurly,
  buttonLabel,
  discountedPrice,
  paymentForm,
  paymentPending,
  fromOptionPage,
  paymentButton,
  cvvElement,
}) => {
  const formRef = React.useRef()
  const recurly = useRecurly()

  const [errorMessage] = useState("")
  const [recurlyToken, setRecurlyToken] = useState({})
  const [isWaiting3dToken, setIsWaiting3dToken] = useState(true)
  const [brand, setBrand] = useState("")
  const [validCard, setValidCard] = useState("empty")
  const [validMonth, setValidMonth] = useState("empty")
  const [validYear, setValidYear] = useState("empty")
  const [validCVV, setValidCVV] = useState("empty")

  const onRecurlySubmit = e => {
    e.preventDefault()
    if (!paymentPending) {
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          // handle error
          // Error message from recurly are in english only so we don't show them
          //setErrorMessage(err.message)
        } else {
          // save the token.id, and submit it to the Recurly API from your server
          setRecurlyToken(token)
          submitRecurly(token)
        }
      })
    }
  }

  const handle3dToken = token => {
    // token.type => 'three_d_secure_action_result'
    // token.id
    submit3dSecureRecurly(token, recurlyToken)
  }

  const handleError = error => {
    console.log("3DERROR", error)
  }

  if (isWaiting3dToken) {
    if (paymentForm?.recurly_3d_token || formData?.recurly_3d_token) {
      setIsWaiting3dToken(false)
    }
  }

  const getLocale = () => {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale
    return locale.split("-").length > 1 ? locale.split("-")[1] : locale
  }

  const creditCardType = () => {
    if (brand === "visa") {
      return <img className={styles.typeImage} src={visaImg} alt="visa-logo" />
    }
    if (brand === "american_express") {
      return <img className={styles.typeImage} src={americanExpressImg} alt="american-express-logo" />
    }
    if (brand === "master") {
      return <img className={styles.typeImage} src={mastercardImg} alt="mastercard-logo" />
    }
    return (
      <>
        <img className={styles.typeImage} src={visaImg} alt="visa-logo" />
        <img className={styles.typeImage} src={mastercardImg} alt="american-express-logo" />
        <img className={styles.typeImage} src={americanExpressImg} alt="mastercard-logo" />
      </>
    )
  }

  if (!isWaiting3dToken && paymentPending) {
    return (
      <ThreeDSecureAction
        className={styles.recurly3dContainer}
        actionTokenId={paymentForm?.recurly_3d_token || formData?.recurly_3d_token}
        onToken={handle3dToken}
        onError={handleError}
      />
    )
  }

  return (
    <form
      className={classNames(styles.paymentForm, { [styles.formOption]: fromOptionPage })}
      onSubmit={onRecurlySubmit}
      ref={formRef}
      data-test="form-payment"
    >
      {header()}

      {errorMessage && <div className={classNames(styles.inlineErrorContainer, styles.errorLabel)}>{errorMessage}</div>}

      {getAPIEnv(consts.config.apiPath) == "prod" ? (
        /** this is a test for Matthieu CORNUT-CHAUVINC to change the name */
        <>
          <input type="text" data-recurly="first_name" hidden value={"Molotov"} name="firstName" readOnly />
          <input type="text" data-recurly="last_name" hidden value={"Molotov"} name="lastName" readOnly />
          <input type="text" data-recurly="country" hidden value={getLocale()} name="country" readOnly />
        </>
      ) : (
        <>
          <input type="text" data-recurly="first_name" name="firstName" placeholder="firstName" />
          <input type="text" data-recurly="last_name" name="lastName" placeholder="lastName" />
          <input type="text" data-recurly="country" value={getLocale()} name="country" />
        </>
      )}

      {formData.error && <div className={styles.errorLabel}>{formData.error.user_message}</div>}

      <div className={classNames(styles.formGroup, styles.formGroupLegacy)}>
        <CardNumberElement
          displayIcon
          style={{
            fontColor: "#7b7f8e",
            fontFamily: "Roboto, sans-serif",
            fontSize: "16px",
            fontWeight: "300",
            placeholder: {
              content: "Numéro de carte",
            },
          }}
          className={classNames(styles.cardNumberElement, {
            [styles.validInputRecurly]: validCard === "valid",
            [styles.errorInputRecurly]: validCard === "notValid",
          })}
          onChange={state => {
            setBrand(state.brand)
            if (!state.focus) {
              setValidCard(state.empty ? "empty" : state.valid ? "valid" : "notValid")
            }
          }}
        />
        <span className={classNames(styles.inputCard, styles.inputCardRecurly)}>
          <span>{creditCardType()}</span>
        </span>
      </div>

      <div className={classNames(styles.inlineFormGroup, styles.formGroupLegacy)}>
        <div className={styles.inlineFormElement}>
          <CardMonthElement
            inputType="select"
            displayIcon
            style={{
              fontColor: "#7b7f8e",
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              fontWeight: "300",
              placeholder: {
                content: "Mois",
              },
            }}
            className={classNames(styles.cardMonthElement, {
              [styles.validInputRecurly]: validMonth === "valid",
              [styles.errorInputRecurly]: validMonth === "notValid",
            })}
            onChange={state => {
              if (!state.focus) {
                setValidMonth(state.empty ? "empty" : state.valid ? "valid" : "notValid")
              }
            }}
          />
        </div>
        <div className={styles.inlineFormElement}>
          <CardYearElement
            inputType="select"
            displayIcon
            style={{
              fontColor: "#7b7f8e",
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              fontWeight: "300",
              placeholder: {
                content: "Année",
              },
            }}
            className={classNames(styles.cardYearElement, {
              [styles.validInputRecurly]: validYear === "valid",
              [styles.errorInputRecurly]: validYear === "notValid",
            })}
            onChange={state => {
              if (!state.focus) {
                setValidYear(state.empty ? "empty" : state.valid ? "valid" : "notValid")
              }
            }}
          />
        </div>
        <div className={classNames(styles.inlineFormElement, styles.lastInlineElement)}>
          <div className={styles.cvcInput}>
            <CardCvvElement
              displayIcon
              style={{
                fontColor: "#7b7f8e",
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                fontWeight: "300",
                placeholder: {
                  content: "Code de sécurité",
                },
              }}
              className={classNames(styles.cardNumberElement, {
                [styles.validInputRecurly]: validCVV === "valid",
                [styles.errorInputRecurly]: validCVV === "notValid",
              })}
              onChange={state => {
                if (!state.focus) {
                  setValidCVV(state.empty ? "empty" : state.valid ? "valid" : "notValid")
                }
              }}
            />
            {cvvElement()}
          </div>
        </div>
      </div>

      <FormBottomMessage messageFormatter={paymentForm.message_bottom_formatter} />

      <div className={classNames(styles.formGroup, styles.formGroupLegacy)}>
        <InputSubmit
          disable={paymentPending}
          customStyle={classNames(InputSubmit.types.yellowFull, styles.submit, { [styles.buttonPending]: paymentPending })}
          value={discountedPrice === 0 ? "Essayer gratuitement" : paymentButton ? paymentButton.title : buttonLabel || i18n.validate}
        />
      </div>

      {footer()}
    </form>
  )
}

RecurlyForm.propTypes = {
  formData: PropTypes.object,
  footer: PropTypes.func,
  header: PropTypes.func,
  submit3dSecureRecurly: PropTypes.func,
  submitRecurly: PropTypes.func,
  buttonLabel: PropTypes.string,
  discountedPrice: PropTypes.number,
  paymentForm: PropTypes.object,
  paymentPending: PropTypes.bool,
  fromOptionPage: PropTypes.bool,
  paymentButton: PropTypes.object,
  cvvElement: PropTypes.func,
}

export default RecurlyForm
