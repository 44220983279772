import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { get } from "lodash"

import Button from "components/Button"
import Carousel from "components/Carousel"
import Icon, { IconTypes } from "components/Icon"
import Thumbnail from "components/Thumbnail"

import pictureHelper from "helpers/picture"
import dateHelper from "helpers/date"
import formatterHelper from "helpers/formatter"
import { getAssetURL } from "helpers/static"

import i18n from "consts/i18n"
import consts from "consts"
import routes from "consts/routes"
import styles from "./index.css"

export default class ProductMain extends Component {
  static propTypes = {
    customStyle: PropTypes.string,
    disabled: PropTypes.bool,
    footer: PropTypes.object,
    onSubscribe: PropTypes.func,
    onReSubscribe: PropTypes.func,
    onUnsubscribe: PropTypes.func,
    product: PropTypes.object,
  }

  render() {
    const {
      customStyle,
      disabled,
      footer,
      onReSubscribe,
      onSubscribe,
      onUnsubscribe,
      product: {
        caption_icons,
        description_formatter,
        description,
        equivalence_code,
        isSubscribe,
        isSubscribeWithAmazon,
        isSubscribeWithApple,
        isSubscribeWithGooglePlay,
        price,
        subscriptionEndAt,
        terms,
        thumbnails,
        type,
        is_dsp,
        platform,
      },
    } = this.props

    const subscribeButtonTopText = get(terms, "cta.top")
    const subscribeButtonBottomText = get(terms, "cta.bottom")
    const isFree = price === 0

    return (
      <div className={customStyle}>
        <p className={styles.description}>{description_formatter ? formatterHelper.styledFormatter(description_formatter) : description}</p>

        {thumbnails && thumbnails.length > 0 && (
          <Carousel customStyle={styles.carousel}>
            {thumbnails.map((thumbnail, key) => {
              let icon
              if (equivalence_code === consts.productEquivalenceCode.option100H) {
                icon = <Icon type={IconTypes.fanion} rootStyle={styles.iconRoot} iconStyle={styles.iconBoomark} />
              }

              return <Thumbnail key={key} icon={icon} thumbnail={thumbnail} />
            })}
          </Carousel>
        )}

        {caption_icons && caption_icons.length > 0 && (
          <Carousel customStyle={classnames(styles.carousel, styles.productChannelCarousel)}>
            {caption_icons.map((caption, key) => {
              if (caption.logo_light) {
                return (
                  <div key={key} className={styles.caption}>
                    <img loading="lazy" src={pictureHelper.replaceProtocol(caption.logo_light.small.url)} className={styles.logo} width="50" />
                  </div>
                )
              }
            })}
          </Carousel>
        )}

        {isFree && (
          <div className={styles.btnContainer}>
            <Link className={styles.lnkDownload} to={routes.download}>
              <Button
                color={Button.colors.yellowFull}
                value={subscribeButtonTopText}
                subtitle={subscribeButtonBottomText}
                customStyle={styles.btnDownload}
              />
            </Link>
          </div>
        )}

        {!isSubscribe && !isFree && (
          <div className={styles.btnContainer}>
            <Button
              color={disabled ? Button.colors.greyFull : Button.colors.yellowFull}
              value={disabled ? i18n.soon : subscribeButtonTopText}
              disabled={disabled}
              onClick={onSubscribe}
              subtitle={disabled ? "" : subscribeButtonBottomText}
              customStyle={classnames(styles.btn, styles.desktopBtn)}
            />
          </div>
        )}
        {isSubscribe && !subscriptionEndAt && (
          <div className={styles.unsubscribe}>
            {is_dsp ? (
              <p>
                Activé via <img loading="lazy" className={styles.imageProductDsp} src={getAssetURL(`image.dsp.${platform}.logoProduct`)} />
              </p>
            ) : (
              <p>
                Vous avez déjà souscrit <br /> à cette option
              </p>
            )}

            {// type === 1 means that user can unsubscribe and resubscribe to this offer
            type === 1 && (
              <div className={styles.btnContainer}>
                <Button
                  color={Button.colors.greyFull}
                  value={i18n.unsubscribe}
                  onClick={onUnsubscribe}
                  customStyle={classnames(styles.btn, styles.desktopBtn, styles.btnInFlow)}
                />
              </div>
            )}
          </div>
        )}
        {type === 1 &&
          isSubscribe &&
          !isSubscribeWithAmazon &&
          !isSubscribeWithApple &&
          !isSubscribeWithGooglePlay &&
          parseInt(subscriptionEndAt, 10) > 0 &&
          equivalence_code !== consts.productEquivalenceCode.rmcSport && (
            <div className={styles.unsubscribed}>
              <p>{"Option souscrite jusqu'au " + dateHelper.format(subscriptionEndAt * 1000)}</p>
              <div className={styles.btnContainer}>
                <Button
                  color={Button.colors.yellowFull}
                  value={"Prolonger mon abonnement"}
                  onClick={onReSubscribe}
                  customStyle={classnames(styles.btn, styles.desktopBtn, styles.btnInFlow)}
                />
              </div>
            </div>
          )}

        {footer}
      </div>
    )
  }
}
