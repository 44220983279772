import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { throttle } from "lodash"
import classNames from "classnames"
import { get } from "lodash"

import Icon, { IconTypes } from "components/Icon"
import Logo from "./assets/logo"
import YellowLogo from "./assets/yellowLogo"
import modalTypes from "containers/Modal/types"

import { setFooterHeight } from "actions/ui"
import { open as openModal } from "actions/modal"

import { getAssetURL } from "helpers/static"
import routesHelper from "helpers/route"
import formatterHelper from "helpers/formatter"

import { selectReferencesModel } from "selectors/references"

import styles from "./index.css"

import consts from "consts"
import i18n from "consts/i18n.js"

@connect(state => ({
  references: selectReferencesModel(state),
  locale: state.locale.locale,
}))
export default class Footer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    pathname: PropTypes.string,
    references: PropTypes.object,
    locale: PropTypes.string,
  }

  watchResize = () => {
    this.props.dispatch(setFooterHeight(this.footer.getBoundingClientRect().height))
  }

  onClickDownload = () => {
    window.location = routesHelper.getDownloadURL("marketing_site")
  }

  displayGlobalInfo = () => {
    const { pathname } = this.props
    return (
      !routesHelper.isDeeplinkPage(pathname) &&
      !routesHelper.isSubscribePage(pathname) &&
      !routesHelper.isTravelPage(pathname) &&
      !routesHelper.isStrapiLandingPage(pathname)
    )
  }

  openCookieModal = e => {
    e?.preventDefault()
    this.props.dispatch(openModal(modalTypes.cookie, null, null, false))
  }

  constructor(props) {
    super(props)
    this.watchResize = throttle(this.watchResize, 200)
    this.state = {
      darkTheme: props.pathname === "/beta",
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.watchResize)
    this.watchResize()
  }

  componentDidUpdate() {
    this.watchResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.watchResize)
  }

  render() {
    const { darkTheme } = this.state
    const { references, locale, pathname } = this.props
    const channels = references
      .getTvBundles()
      .reduce((acc, bundle) => {
        return acc.concat(bundle.channels)
      }, [])
      .filter(channel => !channel.isVirtual())

    const categories = references.categories

    const showSocialIcons = pathname === "/"

    return (
      <footer
        ref={footer => (this.footer = footer)}
        className={classNames(styles.root, {
          [styles.rootDark]: darkTheme,
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.logo}>{darkTheme ? <YellowLogo /> : <Logo />}</div>
            {showSocialIcons && (
              <div className={styles.icons}>
                <a href={consts.externalLink.facebook} target="_blank">
                  <Icon
                    type={IconTypes.facebookFull}
                    rootStyle={styles.social}
                    iconStyle={classNames(styles.icon, styles.iconFacebook, {
                      [styles.iconDark]: darkTheme,
                    })}
                  />
                </a>
                <a href={consts.externalLink.instagram} target="_blank">
                  <Icon
                    type={IconTypes.instagram}
                    rootStyle={styles.social}
                    iconStyle={classNames(styles.icon, {
                      [styles.iconDark]: darkTheme,
                    })}
                  />
                </a>
                <a href={consts.externalLink.twitter} target="_blank">
                  <Icon
                    type={IconTypes.twitter}
                    rootStyle={styles.social}
                    iconStyle={classNames(styles.icon, {
                      [styles.iconDark]: darkTheme,
                    })}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
        {this.displayGlobalInfo() ? (
          <div className={classNames(styles.wrapper, styles.container)}>
            {showSocialIcons && (
              <div className={styles.content}>
                <img
                  src={getAssetURL(`image.Footer.downloadIcons${darkTheme ? "Dark" : ""}`)}
                  className={styles.downloadIcons}
                  onClick={this.onClickDownload}
                />
              </div>
            )}
            <div
              className={classNames(styles.content, {
                [styles.contentDark]: darkTheme,
              })}
            >
              <div
                className={classNames(styles.title, {
                  [styles.titleDark]: darkTheme,
                })}
              >
                Découvrez-nous
              </div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.press + "/fr/"}>{i18n.press}</a>
                </li>
                <li>
                  <a href={consts.externalLink.press + "/en/"}>{i18n.pressRelease}</a>
                </li>
                <li>
                  <a href={"https://advertising.molotov.tv"} target={"_blank"} className={styles.mailto}>
                    Molotov Advertising
                  </a>
                </li>
                <li>
                  <Link to={consts.routes.careers}>{i18n.job_offers}</Link>
                </li>
              </ul>
            </div>
            <div
              className={classNames(styles.content, {
                [styles.contentDark]: darkTheme,
              })}
            >
              <div
                className={classNames(styles.title, {
                  [styles.titleDark]: darkTheme,
                })}
              >
                Besoin d'aide
              </div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.support} target="_blank">
                    {i18n.helpOnline}
                  </a>
                </li>
                <li>
                  <Link to={consts.routes.privacy}>{i18n.privacyPolicy}</Link>
                </li>
                <li>
                  <Link to={consts.routes.mentions}>{i18n.mentions}</Link>
                </li>
                <li>
                  <Link to={consts.routes.cookies}>{i18n.cookies}</Link>
                </li>
                <li>
                  <Link to={consts.routes.gtu}>{i18n.termsOfUse}</Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      this.openCookieModal()
                      window.Didomi.preferences.show()
                    }}
                  >
                    {i18n.cookieSettings.editSettings}
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={classNames(styles.content, {
                [styles.contentDark]: darkTheme,
              })}
            >
              <div
                className={classNames(styles.title, {
                  [styles.titleDark]: darkTheme,
                })}
              >
                Sur Molotov
              </div>
              <ul className={styles.list}>
                {!!channels.length &&
                  consts.onMolotovChannels.map(channel => {
                    const foundChannel = channels.find(chan => channel === get(chan, "data.slug_seo", ""))
                    return (
                      <li key={foundChannel.getTitle()}>
                        <Link to={foundChannel.getUrl(locale)}>{foundChannel.getTitle()} direct</Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
            <div
              className={classNames(styles.content, {
                [styles.contentDark]: darkTheme,
              })}
            >
              <div
                className={classNames(styles.title, {
                  [styles.titleDark]: darkTheme,
                })}
              >
                Nos programmes
              </div>
              <ul className={styles.list}>
                <li>
                  <Link to={formatterHelper.basic(consts.routes.seoHome, { locale })}>{i18n.publicMenu.seoHome}</Link>
                </li>
                <li>
                  <Link
                    to={formatterHelper.basic(consts.routes.seoHomeSection, {
                      locale,
                      section: consts.slugs.tonight_1,
                    })}
                  >
                    {i18n.publicMenu.seoHomeTonight}
                  </Link>
                </li>
                {!!categories.length &&
                  consts.footerCategories.map(category => {
                    const foundCategory = categories.find(cat => category === get(cat, "slug_seo", ""))
                    return (
                      <li key={foundCategory.id}>
                        <Link
                          to={formatterHelper.basic(consts.routes.category, {
                            locale,
                            categoryId: foundCategory.id,
                            categorySlug: foundCategory.slug,
                          })}
                        >
                          {foundCategory.label} streaming
                        </Link>
                      </li>
                    )
                  })}
                <li>
                  <a href={consts.externalLink.olympics2024}>{i18n.publicMenu.olympic2024Footer}</a>
                </li>
              </ul>
            </div>
            <div
              className={classNames(styles.content, {
                [styles.contentDark]: darkTheme,
              })}
            >
              <div
                className={classNames(styles.title, {
                  [styles.titleDark]: darkTheme,
                })}
              >
                Nos offres
              </div>
              <ul className={styles.list}>
                <li>
                  <a href={consts.externalLink.extra}>{i18n.publicMenu.molotovExtra}</a>
                </li>
                <li>
                  <a href={consts.externalLink.extended}>{i18n.publicMenu.molotovExtended}</a>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
        <div
          className={classNames(styles.wrapper, styles.date, {
            [styles.dateDark]: darkTheme,
          })}
        >
          ©Molotov {new Date().getFullYear()}
        </div>
      </footer>
    )
  }
}
