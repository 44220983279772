export default {
  account: "/v2/account",
  activateLinkCode: "/v2/link",
  bookmarksRecord: "/v2/me/record",
  bookmarksScheduled: "/v2/me/schedule-record",
  category: "/v2/seo/categories/:categoryId/sections",
  genre: "/v1/seo/categories/:categoryId/kinds/:kindId",
  channelReplay: "/v2/seo/channels/:channelId/replay/sections",
  channel: "/v2/seo/channels/:channelId/sections",
  checkCampaignCode: "/v2/me/promocode/subscribe/:code",
  checkLinkCode: "/v2/link/codes/:code/exists",
  checkPassword: "/v2/users/me/check_password",
  deleteAllbookmarksRecord: "/v2/me/record/all",
  deleteAllbookmarksScheduled: "/v2/me/scheduled-records",
  deleteFutureBookmarksScheduled: "/v3.2/me/actions/smart-record",
  devices: "/v2/me/devices",
  downloadLink: "https://desktop-auto-upgrade.molotov.tv/:platform/manifest.json",
  episode: "/v2/seo/channels/:channelId/episodes/:episodeId/view",
  giftList: "/v1/gifts/offers/list",
  giftProduct: "/v1/me/gifts/rateplan/:rateplan/product",
  giftCode: "/v1/promocodes/claim",
  loginOCS: "/v2/ocs/login",
  login: "/v3.1/auth/login",
  loseBookmarksOnUnsub: "/v2/me/bookmarks/products/:productId/lost-on-unsub",
  me: "/v2/me",
  config: "/v2/config",
  mtvPayCard: "/v3/me/card/:recurringReference",
  mtvPayInvoices: "/v2/me/invoices",
  mtvPayInvoice: "/v1/me/invoice/:invoiceId",
  paypalInvoice: "/v1/me/paypal-invoice/:invoiceId",
  recurlyInvoice: "/v1/me/recurly-invoice/:invoiceId",
  recurlyPayCard: "/v1/me/payments/recurly/register-card",
  recurlyDeleteCard: "/v1/me/payments/recurly/delete-card",
  mtvPayPaymentMethod: "/v2/me/payment-methods/all",
  mtvPaySecureCheck: "/v2/me/payments/confirm3ds",
  newFunnel: "/v1/me/subscribe/copt",
  notifications: "/v2/me/opt-ins",
  oauthAuthorize: "/v2/oauth/authorize",
  parentalControlPasswordAction: "/v1/me/parental-control/password_action",
  parentalControlLot2: "/v2/me/parental-control/password_action",
  parentalControlPassword: "/v2/me/parental-control/password",
  paypal: {
    getToken: "/v2/me/paypal/token",
    postSuccess: "/v3/me/paypal/:rateplan/subscribe",
  },
  person: "/v2/seo/people/:personId/view",
  purgeBkm: "/v1/me/bookmarks/purge-free",
  productCatalogPublic: "/v2.1/product-catalog",
  productCatalog: "/v2.1/me/product-catalog",
  profiles: "/v2/me/profiles",
  profile: "/v2/me/profile",
  programs: "/v2/programs/landing",
  program: "/v2/seo/channels/:channelId/programs/:programId/view",
  promoCodeClaim: "/v3/promocodes/claim",
  recordMetrics: "/v2/me/record/metrics",
  references: "/v2/seo/references",
  refreshToken: "/v2/auth/refresh/:refreshToken",
  resetPassword: "/v2/auth/reset-password",
  resetEmail: "/v2/auth/reset-email",
  restoreSubscriptionsLegacy: "/v2/me/subscriptions/:subscriptionId/restore",
  scheduledMetrics: "/v2/me/schedule-record/metrics",
  seoConfig: "/v2/seo/me/config",
  seoHome: "/v2/seo/channels/home/sections",
  seolandingMenu: "/v1/seo/landing-menu",
  setupAccount: "/v1/setup-account",
  subCategory: "/v2/seo/categories/:categoryId/sections/:subCategoryId",
  subscriptionsLegacy: "/v2/me/subscriptions",
  subscriptions: "/v2.1/me/subscriptions",
  subscriptionsV3: "/v3/me/subscription",
  unsubscribeEmail: "/v1/opt-out/:emailType/:userId/:hashedEmail",
  updateEmail: "/v2/me/email",
  updateMaxRating: "/v2/me/max-rating",
  updatePassword: "/v2/me/password",
  user: "/v2/me/account",
  unsubscribe: "/v2/me/unsubscription/:subscriptionId/step1",
  unsubscribe2: "/v2/me/unsubscription/:subscriptionId/step2",
  openEurope: "/v1/open-europe/is-france",
  strapi: {
    landing: {
      APIPath: "/data/s",
    },
    cms: {
      hosts: {
        prod: "http://mtv-prod-prs-strapi-pub-alb-13619884.eu-west-3.elb.amazonaws.com",
        staging: "http://mtv-stag-prs-strapi-pub-alb-1930871942.eu-west-3.elb.amazonaws.com",
        dev: "http://mtv-stag-prs-strapi-pub-alb-1930871942.eu-west-3.elb.amazonaws.com",
        local: "http://localhost:1337",
      },
      landingAPIPath: "/api/landings",
    },
  },
}
